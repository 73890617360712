import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable()
export class UsersService {
  constructor (
    private apiService: ApiService
  ) {}
  
  getUsers() {
    return this.apiService.get(`/users`)
      .pipe(map(data => data));
  }
  
  getSingleUser(userId) {
    return this.apiService.get(`/users/`+userId)
      .pipe(map(data => data.user));
  }

  putUsers(userId, bodyParams) {
    return this.apiService.put(`/users/`+userId, bodyParams)
      .pipe(map(data => data));
  }  

  postUsers(bodyParams) {
    return this.apiService.post(`/users`, bodyParams)
      .pipe(map(data => data));
  }
  
  deleteUsers(userId) {
    return this.apiService.delete(`/users/`+userId)
      .pipe(map(data => data));
  }
  
  usersSendReset(userEmail) {
    return this.apiService.put(`/users/send-reset/`+userEmail)
      .pipe(map(data => data));
  }
  
  usersActivate(userToken, bodyParams) {
    return this.apiService.put(`/users/activate/`+userToken, bodyParams)
      .pipe(map(data => data));
  }  

}
