import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable()
export class BenefitsService {
  constructor (
    private apiService: ApiService
  ) {}

  getMarketInfos() {
    return this.apiService.get(`/benefits/getMarketInfos`)
      .pipe(map(data => data));
  }
  
  getBenefits() {
    return this.apiService.get(`/benefits`)
      .pipe(map(data => data));
  }
  
  getSingleBenefit(benefitId) {
    return this.apiService.get(`/benefits/`+benefitId)
      .pipe(map(data => data));
  }

  putBenefits(benefitId, bodyParams) {
    return this.apiService.put(`/benefits/`+benefitId, bodyParams)
      .pipe(map(data => data));
  }  
  
  postBenefits(bodyParams) {
    return this.apiService.post(`/benefits`, bodyParams)
      .pipe(map(data => data));
  }
  
  getRfBenefitsMax() {
    return this.apiService.get(`/benefits/getRfBenefitsMax`)
      .pipe(map(data => data));
  }

  getBenefitConfig(benefitId) {
    return this.apiService.get(`/benefits/`+benefitId+`/benefitConfig`)
      .pipe(map(data => data));
  }
  
  putBenefitConfig(benefitId, bodyParams) {
    return this.apiService.put(`/benefits/`+benefitId+`/benefitConfig`, bodyParams)
      .pipe(map(data => data));
  }
  
  getReviews(benefitId) {
    return this.apiService.get(`/benefits/`+benefitId+`/reviews`)
      .pipe(map(data => data.reviews));
  }
  
  addReviews(benefitId, params) {
    return this.apiService.post(`/benefits/`+benefitId+`/reviews`, params)
      .pipe(map(data => data.review));
  }  

  getImageList() {
    return this.apiService.get(`/benefits/getImageList`)
      .pipe(map(data => data));
  }
  
  getSavingsSimulation(benefitId, amount, monthNum) {
    return this.apiService.get(`/benefits/`+benefitId+`/getSavingsSimulation?amount=`+amount+`&monthNum=`+monthNum)
      .pipe(map(data => data.benefitConfig));
  }
  
  getBenefitsByType(params) {
    return this.apiService.get(`/benefits?taxReductionType=`+params)
      .pipe(map(data => data.benefits));
  }  

  getBenefitsChartData(benefitId) {
    return this.apiService.get(`/benefits/`+benefitId+`/getChartData`)
      .pipe(map(data => data.chartData));
  }

  // Benefits avg rating for bar chart
  getBenefitsAvgRating() {
    return this.apiService.get(`/benefits/getAvgRating`)
      .pipe(map(data => data.reviews));
  }

  // Popular benefits for pie chart
  getPopularBenefits() {
    return this.apiService.get(`/benefits/getPopularBenefits`)
      .pipe(map(data => data.benefits));
  }  

}
