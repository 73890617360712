import { MatChipInputEvent } from '@angular/material/chips';

export class Course {
    _id: string;
    title: string;
    shortInfo: string;
    date: string;
    time: string;
    place: string;
    price: number; 
    isFree: boolean; 
    isMandatory: boolean;
    hasCertificate: boolean;
    categories: string[];

    /**
     * Constructor
     *
     * @param course
     */
    constructor(course?) {
        course = course || {};
        this._id = course._id || null;
        this.title = course.title || '';
        this.shortInfo = course.shortInfo || '';
        //this.handle = course.handle || FuseUtils.handleize(this.name);
        this.date = course.date || '';
        this.time = course.time || '';
        this.place = course.place || '';
        this.date = course.date || '';
        this.price = course.price || 0;
        this.isFree = course.isFree || true;
        this.isMandatory = course.isMandatory || true;
        this.hasCertificate = course.hasCertificate || true;
        this.categories = course.categories || ['general'];
    }


        /**
     * Add category
     *
     * @param {MatChipInputEvent} event
     */
    addCategory(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add category
        if (value) {
            this.categories.push(value);
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    /**
     * Remove category
     *
     * @param category
     */
    removeCategory(category): void {
        const index = this.categories.indexOf(category);

        if (index >= 0) {
            this.categories.splice(index, 1);
        }
    }
}


