import { Component,Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'benio-lg-card',
  templateUrl: './lg-card.component.html',
  styleUrls: ['./lg-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LargeCardComponent implements OnInit {

  @Input() course: any

  @Input('action_link')
  action_link: string

  @Input('index')
  index: string

  image_url: string

  @Input('routerLinkUrl')
  routerLinkUrl: string
  
  @Input('queryParamsUrl')
  queryParamsUrl: string

  // For differentiate course and course deliveries data
  @Input('courseType')
  courseType: string  

  // Storing site url in variable
  siteUrl = location.origin  

  url:string

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) { }


  ngOnInit() {
    // For courses
    if(this.courseType == undefined) {
      if(this.course.img == undefined) {
        //apppend image path
        this.image_url = this.siteUrl+'/assets/images/cards/img23.jpg'
      } else {
        this.image_url = this.siteUrl+this.course.img
      }
    }

    // For deliveries
    if(this.courseType == 'deliveries') {
      if(this.course.course.img == undefined) {
        //apppend image path
        this.image_url = this.siteUrl+'/assets/images/cards/img24.jpg'
      } else {
        this.image_url = this.siteUrl+this.course.course.img
      }    
    }

  }

    //Get routting params in order to pass them to navigation links
    getQueryParams() {
      this._activatedRoute.queryParams.subscribe(params => {
        this.url = params.url
      })    
    }   

  // gotoActionLink(): void {
  //   this._router.navigate([this.action_link])
  // }

}
