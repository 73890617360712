import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import {Skill,UserSkills } from 'app/core';
import * as moment from 'moment';
import { User } from 'app/shared';
//const moment = _moment;

@Injectable()
export class SkillService {
  constructor (
    private apiService: ApiService,
    private _translateService: TranslateService
  ) {}

  getAll() {
    return this.apiService.get(`/skills`)
      .pipe(map(data => data.skills));
  }

  getAllWithParams(params) {
    return this.apiService.get(`/skills`,params)
      .pipe(map(data => data.skills));
  }

  putSkills(skillId, bodyParams) {
    return this.apiService.put(`/skills/`+skillId, bodyParams)
      .pipe(map(data => data.skill));
  }
  
  postSkill(bodyParams) {
    return this.apiService.post(`/skills`, bodyParams)
      .pipe(map(data => data.skill));
  } 

  deleteSkills(skillId) {
    return this.apiService.delete(`/skills/`+skillId)
      .pipe(map(data => data));
  } 

  calculateUserSkillDev(userId,companySkills){
    let skills: Skill[]=companySkills
    let userSkills: UserSkills=new UserSkills()
    let format='YYYY-MM'
    //loop inside all skills, check the ones that are linked to the user and get its evaluations and requirements
    skills.forEach(skill => {
      let skillUser=skill.users.find(user=> user.user == userId)
      let filteredSkill=new Skill()
      Object.assign(filteredSkill,skill)
      filteredSkill.users=skillUser
      if(skillUser!=null){
        userSkills.filteredSkills.push(filteredSkill)
        for(let i=0;i<skillUser.evaluations.length;i++){
          let evalDateIndex:number
          let evalPollDateStr=moment(new Date(skillUser.evaluations[i].pollDate)).format(format)
          evalDateIndex=userSkills.dateEvals.indexOf(evalPollDateStr)
          if(evalDateIndex==-1){ 
            userSkills.dateEvals.push(evalPollDateStr)
            evalDateIndex=userSkills.dateEvals.length-1
            userSkills.sumEvals[userSkills.dateEvals.length-1]=0
            userSkills.sumReqs[userSkills.dateEvals.length-1]=0
          }
          userSkills.sumEvals[evalDateIndex]+=skillUser.evaluations[i].level
          userSkills.sumReqs[evalDateIndex]+=skillUser.evaluations[i].reqLevel
          //chartDevPercs[evalDateIndex]=Math.round(chartSkillEvals[evalDateIndex]/chartSkillReqs[evalDateIndex]*100)
        } 
        if(skillUser.evaluations.length>0) skillUser.lastPerc=Math.round(skillUser.evaluations[0].level/skillUser.requiredLevel*100)
        if(skillUser.evaluations.length==0 && skillUser.requiredLevel){
          if(!userSkills.sumReqs[0]) userSkills.sumReqs[0]=skillUser.requiredLevel
          else userSkills.sumReqs[0]+=skillUser.requiredLevel
        }

      }
    });
    //calculate percentajes and colors
    for(let i=0;i<userSkills.sumReqs.length;i++){
      if(userSkills.sumEvals[i]>0 && userSkills.sumReqs[i]>0) {
        let perc=Math.round(userSkills.sumEvals[i]/userSkills.sumReqs[i]*100)
        userSkills.percSkills.push(perc) 
        let color
        color= perc>=80 ? "#c3e6ce": perc>=60 ? "#ffdfd1" : "#f4c9c6" //mediumseagrenn, lightsalmon and indianred family colors but lighter
        userSkills.colorPercs.push(color)
      }
      else{
        userSkills.percSkills.push(null) 
        userSkills.colorPercs.push("#f4c9c6")
      } 
    }
    return userSkills
  }

}
