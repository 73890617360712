import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class FeedbackService {
  constructor (
    private apiService: ApiService,
    private _translateService: TranslateService
  ) {}

  getAll() {
    return this.apiService.get(`/feedbacks`)
      .pipe(map(data => data.feedbacks));
  }

  getAllWithParams(params) {
    return this.apiService.get(`/feedbacks`,params)
      .pipe(map(data => data.feedbacks));
  }

  // putSuggestions(suggestionId, bodyParams) {
  //   return this.apiService.put(`/suggestions/`+suggestionId, bodyParams)
  //     .pipe(map(data => data.suggestion));
  // }
  
  postFeedback(bodyParams) {
    return this.apiService.post(`/feedbacks`, bodyParams)
      .pipe(map(data => data.feedback));
  } 

  translateType(type:string){
    let translatedType=""
    switch (type) {
      case 'public': translatedType=this._translateService.instant('MY_FEEDBACK.PUBLIC'); break;
      case 'private': translatedType=this._translateService.instant('COMMON_MY_FEEDBACK.PRIVATE'); break;
    } 
    return translatedType
  }

  //Convert the list with a more suitable structure for the page (grouped by person etc.)
  //The structure field naming will gonna be defined by the structure needed by the visual list card element
  groupFeedbacksByUser(feedbackList){
    let groupedList
    let feedbackMap=new Map()
    feedbackMap.clear()

    feedbackList.forEach(feedback => {
      //The map will be groupd by person and field naming will gonna be defined by the structure needed by the visual list card element
      //IF THERE IS no person info in the map initialize with initial values (person info and counts to 0)
      if(feedbackMap.has(feedback.source._id)==false){
        feedbackMap.set(feedback.source._id,{_id:feedback.source._id,name:feedback.source.name,avatar:feedback.source.avatar,createdAt:feedback.createdAt,number:0,numberIcon:"arrow_forward",secondNumber:0,secondIcon: "arrow_back",feedbacksTo:[],feedbacksFrom:[]})
      }
      if(feedbackMap.has(feedback.receiver._id)==false){
        feedbackMap.set(feedback.receiver._id,{_id:feedback.receiver._id,name:feedback.receiver.name,avatar:feedback.receiver.avatar,createdAt:feedback.createdAt,number:0,numberIcon:"arrow_forward",secondNumber:0,secondIcon: "arrow_back",feedbacksTo:[],feedbacksFrom:[]})
      }
      //Update the source's info
      let sourcesFeedbacks=feedbackMap.get(feedback.source._id)
      sourcesFeedbacks.number++
      //list of pople that has recognized the source
      if(sourcesFeedbacks.feedbacksTo.includes(feedback.receiver._id)==false) sourcesFeedbacks.feedbacksTo.push(feedback.receiver._id)
      feedbackMap.set(feedback.source._id,sourcesFeedbacks)
      
      let receiversFeedbacks=feedbackMap.get(feedback.receiver._id)
      receiversFeedbacks.secondNumber++
      //list of pople that has recognized the source
      if(receiversFeedbacks.feedbacksFrom.includes(feedback.source._id)==false) receiversFeedbacks.feedbacksFrom.push(feedback.source._id)
      feedbackMap.set(feedback.receiver._id,receiversFeedbacks)
    })

    //Convert map into list   
    groupedList =[...feedbackMap.values()]
    //Order array giving more importance to the given feedback than the received ones 
    groupedList.sort(function(a,b){
      return (2*b.number+b.secondNumber) - (2*a.number+a.secondNumber)
    })
    return groupedList
  }

}
