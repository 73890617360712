import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { BenefitsService } from 'app/core';

// Month button toggle value defined
enum MonthButtonToggle {
  january = '0',
  february = '1',
  march = '2',
  april = '3',
  may = '4',
  june = '5',
  july = '6',
  august = '7',
  september = '8',
  october = '9',
  november = '10',
  december = '11'      
}

@Component({
  selector: 'app-contract-dialog',
  templateUrl: './contract-dialog.component.html',
  styleUrls: ['./contract-dialog.component.scss']
})
export class ContractDialogComponent implements OnInit {

  monthButtonToggle = MonthButtonToggle

  // Contractor variable used for hide show
  contractorConfirm: boolean = true

  // Api loader
  loading = false

  constructor(
    private _matDialogRef: MatDialogRef<ContractDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _snackBar: MatSnackBar,
    private _translateService: TranslateService,   
    private _benefitsService: BenefitsService
  ) { 

  }

  ngOnInit() {
  }

  // Close dialog
  closeDialog(value) {
    // value used for redirect check
    if(value) {
      this._matDialogRef.close(value)
    } else {
      this._matDialogRef.close()
    }
  }
  
  // Update benefit config
  updateBenefitConfig() {
    this.loading = true
    let bodyParams = {
      benefitConfig: {
        amount: this._data.configAmountValue,
        monthsApplied: this._data.monthButtonToggle,
        savings: this._data.savings,
        savingsMax: this._data.savingsMax
      }      
    }
    this._benefitsService.putBenefitConfig(this._data.benefitId, bodyParams).subscribe((response) => {
      this.openSnackBar(this._translateService.instant('COMMON_MSG.CHANGES_SAVED'))
      this.loading = false
      this.contractorConfirm = false
    }, (error) => {
      this.loading = false
      this.openSnackBar(this._translateService.instant('COMMON_MSG.UNABLE_TO_CONNECT')+' putBenefitConfig')
    })
  }
  
  openSnackBar(message: string) {
    let action = this._translateService.instant('COMMON_MSG.CLOSE')
    this._snackBar.open(message, action, {
      duration: 2000
    })
  }  

}
