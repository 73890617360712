import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { Errors } from 'app/shared/models/errors.model';

@Component({
  selector: 'list-errors',
  templateUrl: './list-errors.component.html',
  styleUrls: ['./list-errors.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListErrorsComponent implements OnInit {

  @Input('errors')
  errors: Errors; 

  formattedErrors: Array<string> = [];

  
  ngOnInit() {
    this.formattedErrors = [];

    if (this.errors.errors) {
      for (let field in this.errors.errors) {
        this.formattedErrors.push(`${field} ${this.errors.errors[field]}`);
      }
    }
  }

/*   set errors(errorList: Errors) {
    this.formattedErrors = [];

    if (errorList.errors) {
      for (let field in errorList.errors) {
        this.formattedErrors.push(`${field} ${errorList.errors[field]}`);
      }
    }
  }; */
  

  errorList() { return this.formattedErrors; }


}