import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable()
export class CoursesService {
  constructor (
    private apiService: ApiService
  ) {}

  getAll() {
    return this.apiService.get(`/courses`)
      .pipe(map(data => data.courses));
  }

  getAllWithParams(params) {
    return this.apiService.get(`/courses`, params)
      .pipe(map(data => data.courses));
  }
  
  getSingleCourse(courseId) {
    return this.apiService.get(`/courses/`+courseId)
      .pipe(map(data => data.course));
  }
  
  getReviews(courseId) {
    return this.apiService.get(`/courses/`+courseId+`/reviews`)
      .pipe(map(data => data.reviews));
  }
  
  addReviews(courseId, params) {
    return this.apiService.post(`/courses/`+courseId+`/reviews`, params)
      .pipe(map(data => data.review));
  }
  
  putCourses(courseId, bodyParams) {
    return this.apiService.put(`/courses/`+courseId, bodyParams)
      .pipe(map(data => data));
  }
  
  putCoursesDeliveries(courseId, deliveriesId, bodyParams) {
    return this.apiService.put(`/courses/`+courseId+`/courseDeliveries/`+deliveriesId, bodyParams)
      .pipe(map(data => data));
  }
  
  deleteCourse(courseId) {
    return this.apiService.delete(`/courses/`+courseId)
      .pipe(map(data => data));
  } 
  
  postCourses(bodyParams) {
    return this.apiService.post(`/courses`, bodyParams)
      .pipe(map(data => data.course));
  }

  postCoursesDeliveries(courseId, bodyParams) {
    return this.apiService.post(`/courses/`+courseId+`/courseDelivery`, bodyParams)
      .pipe(map(data => data.courseDelivery));
  }  

  getCourseDeliveries() {
    return this.apiService.get(`/courseDeliveries/mine`)
      .pipe(map(data => data.courseDeliveries));
  }
  
  // Courses avg rating for bar chart
  getCoursesAvgRating() {
    return this.apiService.get(`/courses/getAvgRating`)
      .pipe(map(data => data.reviews));
  }
  
  // Popular courses for pie chart
  getPopularCourses() {
    return this.apiService.get(`/courses/getPopularCourses`)
      .pipe(map(data => data.courses));
  }  

}
