export class Poll {
    _id: string;
    isTemplate: boolean;

    /**
     * Constructor
     *
     * @param expense
     */
    constructor(poll?) {
        poll = poll || {};
        this._id = poll._id || null;
        this.isTemplate = poll.isTemplate || false;
    }
}

export enum pollCategoryTypes {
    ENGAGEMENT= 'ENGAGEMENT',
    EMPLOYEE_JOURNEY= 'EMPLOYEE_JOURNEY',
    BENEFITS ='BENEFITS',
    CULTURE= 'CULTURE',
    MANAGEMENT ='MANAGEMENT',
    EMPLOYER_BRANDING = 'EMPLOYER_BRANDING',
    ASSESMENT= 'ASSESMENT'
}

export enum pollTypes {
    PerformanceFromSupervisor= 'PERF_FROM_SUPER',
    PerformanceFromPeer= 'PERF_FROM_PEER',
    PerformanceFromLower= 'PERF_FROM_LOWER',
    ENPS= 'ENPS',
    Satisfaction= 'SATISFACTION',
    Engagement= 'ENGAGEMENT',
    Selection= 'SELECTION',
    Welcome= 'WELCOME',
    Welcome5= 'WELCOME5',
    Integration= 'INTEGRATION',
    Image= 'IMAGE',
    Goobye= 'GOODBYE',
    KnowledgeManagement= 'KNOWLEDGE',
    Health= 'HEALTH',
    Benefits= 'BENEFITS',
    CultureAssesment= 'CULTURE_ASSESMNT',
    ValuesAssesment= 'VALUES_ASSESMNT',
    CultureDefinition= 'CULTURE_DEF',
    Roles= 'ROLES',
    Leadership= 'LEADERSHIP',
    Teamwork= 'TEAMWORK',
}

