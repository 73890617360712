export class Expense {
    _id: string;
    category: string;
    type: string;
    concept: string;
    quantity: number; 
    date: string; 
    payingDate: string; 
    isAccepted: boolean;

    /**
     * Constructor
     *
     * @param expense
     */
    constructor(expense?) {
        expense = expense || {};
        this._id = expense._id || null;
        this.category = expense.category || '';
        this.type = expense.type || '';
        this.concept = expense.concept || '';
        this.quantity = expense.quantity || 0;
        this.date = expense.date || '';
        this.payingDate = expense.payingDate || '';
        this.isAccepted = expense.isAccepted || true;
    }
}


