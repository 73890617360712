export const locale = {
    lang: 'eus',
    data: {
        'NAV': {
            'APPLICATIONS': '',
            'HOME': 'Sarrera',
            'PAYROLL': 'Nominak'
        }
    }
};
