import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-image-select-dialog',
  templateUrl: './image-select-dialog.component.html',
  styleUrls: ['./image-select-dialog.component.scss']
})
export class ImageSelectDialogComponent implements OnInit {

  imgSelectBorder = []

  imageDatas: any = []

  // Storing site url in variable
  siteUrl = location.origin  

  constructor(
    private _matDialogRef: MatDialogRef<ImageSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,    
  ) { 

  }

  ngOnInit(): void {
    this.fillImagesInArray()
  }

  // All local images fill into imageDatas array
  fillImagesInArray() {
    for(let i = 1; i < 62; i++) {
      this.imageDatas.push('/assets/images/cards/img'+i+'.jpg')
    }
  }

  imageSelected(index, value) {
    this.imgSelectBorder[index] = true
    setTimeout (() => {
      this.closeDialog(value)
    }, 300)
  }

  // Close dialog
  closeDialog(value) {    
    if(value) {
      this._matDialogRef.close(value)
    } else {
      this._matDialogRef.close()
    }
  }  

}
