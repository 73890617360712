import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { Expense } from '../models';
import { map } from 'rxjs/operators';


@Injectable()
export class ExpensesService {
  constructor (
    private apiService: ApiService
  ) {}

/*   add(slug, payload): Observable<Comment> {
    return this.apiService
    .post(
      `/articles/${slug}/comments`,
      { comment: { body: payload } }
    ).pipe(map(data => data.comment));
  } */

  getAll(): Observable<Expense[]> {
    return this.apiService.get(`/expenses`)
      .pipe(map(data => data.expenses));
  }

/*   destroy(commentId, articleSlug) {
    return this.apiService
           .delete(`/articles/${articleSlug}/comments/${commentId}`);
  } */

}
