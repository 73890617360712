import { Component,Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'benio-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageHeaderComponent implements OnInit {

  @Input('header')
  header: string ='';

  @Input('subHeader')
  subHeader: string=''; 

  constructor() { }

  ngOnInit() {
  }

}
