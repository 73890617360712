import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
    {
        id: "admin-pages",
        title: "Admin Pages",
        translate: "NAV.ADMIN",
        type: "group",
        children: [
            {
                id: "admin-home",
                title: "Dashboard",
                translate: "NAV.ADMIN_DASHBOARD",
                type: "item",
                icon: "dashboard",
                url: "/pages/home/admin-home",
            },
            {
                id: "admin-satisfaction",
                title: "Admin NPS",
                translate: "NAV.ADMIN_SATISFACTION",
                type: "item",
                icon: "sentiment_satisfied_alt",
                url: "/pages/satisfaction/admin-satisfaction",
            },
            {
                id: "admin-polls",
                title: "Admin Polls",
                translate: "NAV.ADMIN_POLLS",
                type: "item",
                icon: "poll",
                url: "/pages/polls/admin-polls",
            },
            {
                id: "admin-suggestions",
                title: "Admin Suggestions",
                translate: "NAV.ADMIN_SUGGESTIONS",
                type: "item",
                icon: "question_answer",
                url: "/pages/suggestions/admin-suggestions",
            },
            {
                id: "admin-skills",
                title: "Admin Feedback",
                translate: "NAV.ADMIN_FEEDBACK",
                type: "item",
                icon: "thumb_up",
                url: "/pages/feedback/admin-skills",
            },
            {
                id: "admin-branding",
                title: "Admin Employer Branding",
                translate: "NAV.ADMIN_BRANDING",
                type: "item",
                icon: "trending_up",
                url: "/pages/benefits/admin-benefits",
            },
            {
                id: "admin-courses",
                title: "Admin Courses",
                translate: "NAV.ADMIN_COURSES",
                type: "item",
                icon: "school",
                url: "/pages/education/admin-courses",
            },
            // {
            //     id: 'admin-payrolls',
            //     title: 'Admin Payrolls',
            //     translate: 'NAV.ADMIN_PAYROLLS',
            //     type: 'item',
            //     icon: 'description',
            //     url: '/pages/payroll/admin-payrolls'
            // },
            {
                id: "company",
                title: "Company",
                translate: "NAV.COMPANY",
                type: "item",
                icon: "business",
                url: "/pages/company",
            },
            {
                id: "users",
                title: "Users",
                translate: "NAV.USERS",
                type: "item",
                icon: "people_outline",
                url: "/pages/users",
            },
        ],
    },
    {
        id: "user-pages",
        title: "Pages",
        translate: "NAV.USER",
        type: "group",
        children: [
            {
                id: "user-home",
                title: "Home",
                translate: "NAV.HOME",
                type: "item",
                icon: "home",
                url: "/pages/home/user-home",
            },
            {
                id: "polls",
                title: "Polls",
                translate: "NAV.POLLS",
                type: "item",
                icon: "poll",
                url: "/pages/polls/my-polls",
            },
            {
                id: "suggestions",
                title: "Suggestions",
                translate: "NAV.SUGGESTIONS",
                type: "item",
                icon: "question_answer",
                url: "/pages/suggestions/my-suggestions",
            },
            {
                id: "feedback",
                title: "Feedback",
                translate: "NAV.FEEDBACK",
                type: "item",
                icon: "thumb_up",
                url: "/pages/feedback/my-feedback",
            },
            {
                id: "benefits",
                title: "Benefits",
                translate: "NAV.BENEFITS",
                type: "item",
                icon: "trending_up",
                url: "/pages/benefits/my-benefits",
            },
            {
                id: "education",
                title: "Education",
                translate: "NAV.EDUCATION",
                type: "item",
                icon: "school",
                url: "/pages/education/my-courses",
            },
            {
                id: "my-payrolls",
                title: "My Payrolls",
                translate: "NAV.MY_PAYROLLS",
                type: "item",
                icon: "euro_symbol",
                url: "/pages/payroll/my-payrolls",
            },
        ],
    },
];
