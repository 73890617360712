import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { Poll,pollTypes,pollCategoryTypes } from '../models';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { ResetPasswordModule } from 'app/auth/reset-password/reset-password.module';
import { negate } from 'lodash';

@Injectable()
export class PollService {
  constructor (
    private apiService: ApiService
  ) {}

  getAll(): Observable<Poll[]> {
    return this.apiService.get(`/polls`)
      .pipe(map(data => data.polls));
  }

  getAllWithParams(params) {
    return this.apiService.get(`/polls`, params)
      .pipe(map(data => data.polls));
  }

  getSingle(pollId) {
    return this.apiService.get(`/polls/`+pollId)
      .pipe(map(data => data.poll));
  }

  putPolls(pollId, bodyParams) {
    return this.apiService.put(`/polls/`+pollId, bodyParams)
      .pipe(map(data => data.poll));
  }
  
  postPolls(bodyParams) {
    return this.apiService.post(`/polls`, bodyParams)
      .pipe(map(data => data.poll));
  }

  getPollSchedulers(params) {
    return this.apiService.get(`/pollSchedulers`,params)
      .pipe(map(data => data.pollSchedulers));
  }
  
  putPollSchedulers(pollSchedulerId, bodyParams) {
    return this.apiService.put(`/pollSchedulers/`+pollSchedulerId, bodyParams)
      .pipe(map(data => data.pollScheduler));
  }
  
  postPollSchedulers(bodyParams) {
    return this.apiService.post(`/pollSchedulers`, bodyParams)
      .pipe(map(data => data.pollScheduler));
  }


  getIconFromCategory(category){
    //Add an icon depending on the category
    let icon
    switch (category) {
      case pollCategoryTypes.ENGAGEMENT: icon="sentiment_satisfied_alt"; break;
      case pollCategoryTypes.EMPLOYEE_JOURNEY: icon="list"; break;
      case pollCategoryTypes.BENEFITS: icon="trending_up"; break;
      case pollCategoryTypes.CULTURE: icon="thumb_up"; break;
      case pollCategoryTypes.MANAGEMENT: icon="business"; break;
      case pollCategoryTypes.EMPLOYER_BRANDING: icon="record_voice_over"; break;
      case pollCategoryTypes.ASSESMENT: icon="thumb_up"; break;
    } 
    return icon
  }

  calculatePollResults(poll,userId){
    let percResult=0
    let avgQuestionResult=0
    let avgPersonResult=0
    let myResultIndex=0
    let satisfied =0
    let unsatisfied=0
    let passives=0
    let personNum=poll.questions[0].results.length //All the questions have the same amount of answers (if non answered 0)

    poll.personResult=[]
    //Add an icon depending on the category
    poll.icon=this.getIconFromCategory(poll.category)

    //format date
    poll.createdAt = moment(poll.createdAt).format("YYYY-MM-DD")
    //i is the people's index
    for(let i=0;i<personNum;i++){
      //the satisfaction of each people is calculate really simple with the summ of all answers
      let personResult=0
      let answeredQuestionNum=0
      for(let j=0;j<poll.questions.length;j++){
        personResult+=poll.questions[j].results[i]
        if(poll.questions[j].results[i]!=null) answeredQuestionNum++
      }
      //calculate average result just taking into account the non neutral answers 
      if(personResult!==0) personResult=personResult/answeredQuestionNum
      //the total result is calculated with the percentage of satisfied (>1) of unsitisfied (<-1) the neutral are not taken into account (between -1 and 1)
      if(personResult>6) satisfied++
      if(personResult>=4 && personResult<=6) passives++
      if(personResult<4) unsatisfied++
      poll.personResult[i]=Math.round(personResult *100)/ 100 //round to 2 digits
      avgPersonResult+=personResult
      //this is useful for the case of the results of a non admin user where we need to know which answers are the user's
      if(userId==poll.answeredBy[i]) myResultIndex=i
    }
    //If there is at least an answer calculate the total percentage result and the avg person result
    percResult=0
    if(personNum>0) {
      if(satisfied-unsatisfied!=0) percResult=(satisfied-unsatisfied)/personNum*100;
      if(avgPersonResult!=0) avgPersonResult=avgPersonResult/personNum
    }

    //The calculation of the total Result could be more efficient if done in previous loop but the code  harder to understand
    for(let i=0;i<poll.questions.length;i++){
      poll.questions[i].totalResult=0
      let answeredQuestionNum=0 
      for(let j=0;j<personNum;j++){
        poll.questions[i].totalResult+=poll.questions[i].results[j]
        if(poll.questions[i].results[j]!=null) answeredQuestionNum++
      }
      //calculate average result just taking into account the non neutral answers 
      if(answeredQuestionNum!=0) {
        poll.questions[i].totalResult=(poll.questions[i].totalResult/answeredQuestionNum)
        poll.questions[i].totalResult = Math.round(poll.questions[i].totalResult*100) / 100
        poll.questions[i].progressBarColor= poll.questions[i].totalResult>6 ? 'accent' : poll.questions[i].totalResult < 4 ? 'warn' : 'primary'
      }
      avgQuestionResult+=poll.questions[i].totalResult
    }
    //Average of the total Result
    if(avgQuestionResult!=0 && poll.questions.length!=0) avgQuestionResult=avgQuestionResult/poll.questions.length

    //Assign values to object
    poll.avgQuestionResult=Math.round(avgQuestionResult * 100) /100 //Round to 2 digits
    poll.avgPersonResult=Math.round(avgPersonResult * 100) /100 //Round to 2 digits
    poll.myResultIndex = myResultIndex
    poll.percResult=Math.round(percResult * 100) /100 //Round to 2 digits
    poll.percResultProgressColor=percResult>20 ? 'accent' : percResult < -20 ? 'warn' : 'primary'
    poll.satisfied=satisfied
    poll.unsatisfied=unsatisfied
    poll.passives=passives
    poll.answers=personNum
    if(poll.numSent>0 && personNum>0) poll.answerRatio=personNum/poll.numSent*100
    poll.estimatedDuration=Math.ceil(poll.questions.length /3) +" min"
    return poll
  }

  calculateEnpsPollResults(poll,userId){
    let percResult=0
    let myResultIndex=0
    let promotors =0
    let detractors=0
    let passives=0
    let personNum=poll.questions[0].results.length //All the questions have the same amount of answers (if non answered 0)

    poll.personResult=[]
    //Add an icon depending on the category
    poll.icon=this.getIconFromCategory(poll.category)

    //format date
    poll.createdAt = moment(poll.createdAt).format("YYYY-MM-DD")
    //i is the people's index
    for(let i=0;i<personNum;i++){
      //the satisfaction of each people is calculate really simple with the summ of all answers
      let personResult=0
      let answeredQuestionNum=0
      for(let j=0;j<poll.questions.length;j++){
        personResult+=poll.questions[j].results[i]
        if(poll.questions[j].results[i]!=null) answeredQuestionNum++
      }
      //calculate average result just taking into account the non neutral answers 
      if(personResult!==0) personResult=personResult/answeredQuestionNum
      //the total result is calculated with the percentage of satisfied (>1) of unsitisfied (<-1) the neutral are not taken into account (between -1 and 1)
      if(personResult>8) promotors++
      if(personResult>=7 && personResult<=8) passives++
      if(personResult<7) detractors++
      poll.personResult[i]=Math.round(personResult *100)/ 100 //round to 2 digits
      //this is useful for the case of the results of a non admin user where we need to know which answers are the user's
      if(userId==poll.answeredBy[i]) myResultIndex=i
    }
    //If there is at least an answer calculate the total percentage result and the avg person result
    percResult=0
    if(personNum>0) {
      if(promotors-detractors!=0) percResult=(promotors-detractors)/personNum*100;
    }

    //Assign values to object
    poll.myResultIndex = myResultIndex
    poll.percResult=Math.round(percResult * 100) /100 //Round to 2 digits
    poll.percResultProgressColor=percResult>20 ? 'accent' : percResult < -20 ? 'warn' : 'primary'
    poll.promotors=promotors
    poll.percPromotors=0
    if(personNum>0) poll.percPromotors=Math.round(promotors/personNum*10000)/100
    poll.detractors=detractors
    poll.percDetractors=0
    if(personNum>0) poll.percDetractors=Math.round(detractors/personNum*10000)/100
    poll.passives=passives
    poll.percPassives=0
    if(personNum>0) poll.percPassives=Math.round(passives/personNum*10000)/100
    poll.answers=personNum
    if(poll.numSent>0 && personNum>0) poll.answerRatio=personNum/poll.numSent*100
    poll.estimatedDuration=Math.ceil(poll.questions.length /3) +" min"
    return poll
  }

  //TODO review if it's correct to use other services as feedbacks and suggestions
 calculateEngagement(user,polls,feedbacks,companyEmployeeNum){
    //FIRST OF ALL WE INITIALIZE result arrays with 0 values
    let now:Date=new Date()
    let result:any={}
    result.engagement=0
    result.lastEngagementPollResult=0
    result.lastSemesterEnpsAvg=0
    result.lastSemesterFeedbacksSent=0
    let lastSemesterEnpsPollResults=[]
    let enpsPollResults=[]
    let satisPollResults=[]
    result.semesterEngagement=[]
    result.semesterDateStrings=[]
    result.monthPollParticipationComparison=[]
    result.monthFeedbackComparison=[]
    result.monthSuggestionComparison=[]
    let userCreatedAt:Date= new Date(user.createdAt)
    let numMonths= now.getMonth() -userCreatedAt.getMonth() +12 * (now.getFullYear() - userCreatedAt.getFullYear())
    let numSemesters=Math.ceil(numMonths/6)
    for(let i=0;i<numSemesters;i++){ result.semesterEngagement.push(0)}
    for(let i=0;i<numMonths;i++){
      enpsPollResults.push(null)
      satisPollResults.push(null)
      result.monthPollParticipationComparison.push(0)
      result.monthFeedbackComparison.push(0)
      result.monthSuggestionComparison.push(0)
    } 
    //LOOP POLLS in order to get engagement and feedback related poll data
    let foundLastEngagementSatis=false
    let loopSemester:number=0,previousLoopSemester:number= 0
    let loopMonth:number=0,previousLoopMonth:number=0
    let monthTotalPollNum:number=0
    let monthTotalAnswerNum:number=0
    let monthUserPollNum:number=0
    let monthUserAnswerNum:number=0
    let monthTotalFeedbackNum=[]
    let monthUserFeedbackNum=[]
    for(let i=0;i<numMonths;i++){
      monthTotalFeedbackNum.push(0)
      monthUserFeedbackNum.push(0)
    }
    polls.forEach(poll=>{
      previousLoopMonth=loopMonth
      previousLoopSemester=loopSemester
      loopMonth=now.getMonth() - new Date(poll.createdAt).getMonth() + 12 * (now.getFullYear() - new Date(poll.createdAt).getFullYear())
      if(loopMonth>0) loopSemester=Math.floor(loopMonth/6)
      //If the polls change month, calculate participation result
      if(loopMonth!=previousLoopMonth && monthTotalPollNum>0){
        let percPartTotal=0,percPartUser=0
        percPartTotal=monthTotalAnswerNum/monthTotalPollNum
        if(monthUserPollNum>0) percPartUser=monthUserAnswerNum/monthUserPollNum
        result.monthPollParticipationComparison[previousLoopMonth]=(percPartUser-percPartTotal)*100 //RESULT
        //reset counters
        monthTotalPollNum=0
        monthTotalAnswerNum=0
        monthUserPollNum=0
        monthUserAnswerNum=0
      }
      monthTotalPollNum+=poll.surveyee.length
      monthTotalAnswerNum+=poll.answeredBy.length
      if(poll.surveyee.includes(user._id)) monthUserPollNum++
      if(poll.answeredBy.includes(user._id)) monthUserAnswerNum++
      //IN THE CASE OF ENGAGEMENT,ENPS POLLS, GET ADDITIONAL INFO, NOT JUST PARTICIPATION
      switch(poll.type){
        case pollTypes.Engagement:
        case pollTypes.Satisfaction:
          if(poll.answeredBy.includes(user._id) ){
            poll=this.calculatePollResults(poll,user._id)
            if(foundLastEngagementSatis==false){    
              result.lastEngagementPollResult=poll.personResult[poll.myResultIndex]
              foundLastEngagementSatis=true
            }
            satisPollResults[loopMonth]=poll.personResult[poll.myResultIndex]
          }
          break;
        case pollTypes.ENPS:
          if(poll.answeredBy.includes(user._id)){
            poll=this.calculatePollResults(poll,user._id)
            if(loopSemester==0) lastSemesterEnpsPollResults.push(poll.personResult[poll.myResultIndex])
            enpsPollResults[loopMonth]=poll.personResult[poll.myResultIndex]
          }
          break;
        case pollTypes.PerformanceFromLower:
        case pollTypes.PerformanceFromSupervisor:
        case pollTypes.PerformanceFromPeer:
          if(poll.answeredBy.includes(user._id)) monthUserFeedbackNum[loopMonth]++
          monthTotalFeedbackNum[loopMonth]++
      }
    })
    //LOOP FEEDBACKS in order to get feedback related data
    feedbacks.forEach(feedback => {
      loopMonth=now.getMonth() - new Date(feedback.createdAt).getMonth() + 12 * (now.getFullYear() - new Date(feedback.createdAt).getFullYear())
      if(feedback.source._id==user._id) {
        monthUserFeedbackNum[loopMonth]++
      }
      monthTotalFeedbackNum[loopMonth]++
    });
    //CALCULATE ENGAGEMENT RESULTS AND SET RESULT OBJECT VALUES
    //First of all calculate feedback comparison and semester feedbacks
    for(let i=0;i<numMonths;i++) {
      let avgUserFeedbackNum=0
      if(companyEmployeeNum>0) avgUserFeedbackNum=monthTotalFeedbackNum[i]/companyEmployeeNum
      //TODO CALCULATE COMPARING PERCENTAJE OF USERS THAT ARE DOING BETTER OR WORSE
      result.monthFeedbackComparison[i]=this.diffPercent(monthUserFeedbackNum[i],avgUserFeedbackNum)
    }
    for(let i=0;i<Math.min(6,numMonths);i++) result.lastSemesterFeedbacksSent+=monthUserFeedbackNum[i]
    //Calculate engagement for every semester
    for(let i=0;i<numSemesters;i++){
      let semesterEngagement=0
      let avgPollSatis=null,summPollSatis=0,nonNullSatisPollCount=0
      let numMonthsSemester= i==(numSemesters-1) ? i%6 : 6
      //calculate avgPollSatis
      for(let j=i*6;j<(i*6+numMonthsSemester);j++){
        if(satisPollResults[j]!=null){
          summPollSatis+=satisPollResults[j]
          nonNullSatisPollCount++
        }
      }
      if(nonNullSatisPollCount>0) avgPollSatis=(summPollSatis/nonNullSatisPollCount).toFixed(2)
      //calculate avgEnps
      let avgEnps=null,summEnps=0,nonNullEnps=0
      for(let j=i*6;j<(i*6+numMonthsSemester);j++){
        if(enpsPollResults[j]!=null){
          summEnps+=enpsPollResults[j]
          nonNullEnps++
        }
      }
      if(nonNullEnps>0) {
        avgEnps=(summEnps/nonNullEnps).toFixed(2)
        if(i==0) result.lastSemesterEnpsAvg=avgEnps //SET ALSO RESULT FIELD
      }
      //set partial engagement taking into account satis polls and enps
      if(avgPollSatis!=null && avgEnps==null) semesterEngagement=avgPollSatis
      if(avgPollSatis!=null && avgEnps!=null) semesterEngagement=avgPollSatis*0.7+avgEnps*0.3 //satis gets more importance than enps
      if(avgPollSatis==null && avgEnps!=null) semesterEngagement=avgEnps
      //if there is data in satis polls or enps we take into account feedbacks, otherwise we consider that the info is not enough and semesterEngagement=0
      if(avgPollSatis!=null || avgEnps!=null){
        //TODO TODO
      }
      result.semesterEngagement[i]=semesterEngagement*10 //ADD A VALUE TO RESULT.SEMESTER ENGAGEMENT FIELD
      if(i==0) result.engagement=semesterEngagement*10 //SET RESULT.ENGAGEMENT FIELD
    }
    user.engagement=result.engagement

    //reverse date data arrays in order to order array elements from old to recent
    result.semesterEngagement=result.semesterEngagement.reverse()
    result.monthPollParticipationComparison=result.monthPollParticipationComparison.reverse()
    result.monthFeedbackComparison=result.monthFeedbackComparison.reverse()
    result.monthSuggestionComparison=result.monthSuggestionComparison.reverse()
    return result
  }

  //TODO REVIEW AND IMPROVE
  private diffPercent(a, b) {
    let value=0
    if(a==0 && b>0) value=b*100
    if(a==0 && b==0) return 0
    if(a>0 && b>0) value= +Math.abs(100 - b / a * 100).toFixed(10);
    value=Math.min(100,value)
    if(a<b) value=-value
    return value
    //return Math.abs(100-b/a*100)
    //return ( a<b ? -((b - a) * 100) / a : ((a - b) * 100) / b ) 
    //return  100 * Math.abs( ( a - b ) / ( (a+b)/2 ) );
   }
  

}
