import {
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
    ViewChild,
    TemplateRef,
    ViewContainerRef,
    ChangeDetectorRef,
} from "@angular/core";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";
import { MatButton } from "@angular/material/button";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Notification } from "app/core/models/notification.model";
import { MatSnackBar } from "@angular/material/snack-bar";

import * as _ from "lodash";

import { FuseConfigService } from "@fuse/services/config.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";

import { navigation } from "app/navigation/navigation";
import { User } from "app/shared/models";
import { UserService } from "app/shared/services/user.service";
import { NotificationsService } from "app/core";

import { locale as spanish } from "i18n/es";
import { locale as euskera } from "i18n/eus";
import { colorSets } from "@swimlane/ngx-charts";
import { elementAt } from "rxjs-compat/operator/elementAt";

@Component({
    selector: "toolbar",
    templateUrl: "./toolbar.component.html",
    styleUrls: ["./toolbar.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
    @ViewChild("notificationsOrigin") private _notificationsOrigin: MatButton;
    @ViewChild("notificationsPanel")
    private _notificationsPanel: TemplateRef<any>;

    // Query string parameters
    icon: any;
    title: any;
    url: any;

    notifications: Notification[]; //Objeto de array
    notification: any;
    unreadCount: number = 0;
    private _overlayRef: OverlayRef;

    isFirstTime: boolean = true; //If it's the first time that is initialized the notifications data
    currentUser: User;
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];

    // Private
    private _unsubscribeAll: Subject<any>;

    // Get userId
    userId = localStorage.getItem("userId");

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _userService: UserService,
        private _notificationsService: NotificationsService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _viewContainerRef: ViewContainerRef,
        private _overlay: Overlay,
        private _snackBar: MatSnackBar
    ) {
        // Set the defaults
        this.userStatusOptions = [
            {
                title: "Online",
                icon: "icon-checkbox-marked-circle",
                color: "#4CAF50",
            },
            {
                title: "Away",
                icon: "icon-clock",
                color: "#FFC107",
            },
            {
                title: "Do not Disturb",
                icon: "icon-minus-circle",
                color: "#F44336",
            },
            {
                title: "Invisible",
                icon: "icon-checkbox-blank-circle-outline",
                color: "#BDBDBD",
            },
            {
                title: "Offline",
                icon: "icon-checkbox-blank-circle-outline",
                color: "#616161",
            },
        ];

        this.languages = [
            {
                id: "es",
                title: "Español",
            },
            {
                id: "eus",
                title: "Euskera",
            },
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.getQueryParams();

        //Subscribe to user changes
        this._userService.currentUser.subscribe((User) => {
            this.currentUser = User;
        });
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar =
                    settings.layout.navbar.position === "top";
                this.rightNavbar = settings.layout.navbar.position === "right";
                this.hiddenNavbar = settings.layout.navbar.hidden === true;

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {
            id: this._translateService.currentLang,
        });

        this._fuseTranslationLoaderService.loadTranslations(spanish, euskera);

        this.populateGetNotifications();
    }

    // Get current query params
    getQueryParams() {
        this._activatedRoute.queryParams.subscribe((params) => {
            (this.icon = params.icon),
                (this.title = params.title),
                (this.url = params.url);
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        console.log(value);
    }

    logout(): void {
        this._userService.purgeAuth();
        localStorage.clear();
        this._router.navigate(["/pages/auth/login"]);
    }

    editProfile(tabIndex) {
        this._router.navigate(["/pages/users/profile"], {
            queryParams: {
                icon: "assignment_ind",
                title: "PROFILE.TITLE",
                url: this.url,
                detailTranslate: "PROFILE.TITLE",
                tabIndex: tabIndex,
                userId: this.userId,
                isForAdminUser: false,
                //breadcrumbLevel: '2'
            },
        });
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    /**
     *
     */

    //Get notifications list and call getQueryParams function
    populateGetNotifications() {
        let params = {
            limit: 10,
        };

        this._notificationsService
            .getAllWithParams(params)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((notifications: Notification[]) => {
                // Load the notifications
                this.notifications = notifications;
                // Calculate the unread count of notifications
                this._calculateUnreadCount();
            });
    }

    /**
     * Open the notifications panel
     */
    openPanel(): void {
        // Return if the notifications panel or its origin is not defined
        if (!this._notificationsPanel || !this._notificationsOrigin) {
            return;
        }

        //Return if is not the first time that has been clicked the panel
        if (this.isFirstTime === false) {
            this.populateGetNotifications();
        } else this.isFirstTime = false;

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(
            new TemplatePortal(this._notificationsPanel, this._viewContainerRef)
        );
    }

    /**
     * Update the read status of a notification read: false/true
     */
    updateNotificationStatus(notificationIdValue, isReadValue) {
        let bodyParams = {
            notification: {
                read: isReadValue,
            },
        };

        this._notificationsService
            .putNotification(notificationIdValue, bodyParams)
            .subscribe(
                (response) => {
                    this.openSnackBar(
                        this._translateService.instant(
                            "COMMON_MSG.CHANGES_SAVED"
                        )
                    );
                    this._calculateUnreadCount();
                },
                (error) => {
                    this.openSnackBar(
                        this._translateService.instant(
                            "COMMON_MSG.UNABLE_TO_CONNECT"
                        ) + " updateNotifications"
                    );
                }
            );
    }

    openSnackBar(message: string) {
        let action = this._translateService.instant("COMMON_MSG.CLOSE");
        this._snackBar.open(message, action, {
            duration: 2000,
        });
    }

    /**
     * Close the notifications panel
     */
    closePanel(): void {
        this._overlayRef.detach();
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): void {
        this.notifications.forEach((notification) => {
            if (notification.read != true) {
                notification.read = true;
                let bodyParams = notification.read;
                this.updateNotificationStatus(notification._id, bodyParams);
            }
        });
    }

    /**
     * Toggle read status of the given notification
     */
    toggleRead(notification: Notification): void {
        // Toggle the read status
        notification.read = !notification.read;
        let bodyParams = notification.read;

        // Update the notification
        this.updateNotificationStatus(notification._id, bodyParams);
    }

    toggleNotificationType(moduleType, notifyAdmin): void {
        if (notifyAdmin === false) {
            switch (moduleType) {
                case "POLL":
                    this._router.navigate(["/pages/polls/my-polls"], {
                        queryParams: {},
                    });
                    break;
                case "SUGGESTIONS":
                    this._router.navigate(
                        ["/pages/suggestions/my-suggestions"],
                        {
                            queryParams: {},
                        }
                    );
                    break;
                case "FEEDBACK":
                    this._router.navigate(["/pages/feedback/my-feedback"], {
                        queryParams: {},
                    });
                    break;
                case "COURSES":
                    this._router.navigate(["/pages/education/my-courses"], {
                        queryParams: {},
                    });
                    break;
                default:
                    break;
            }
        } else {
            switch (moduleType) {
                case "POLL":
                    this._router.navigate(["/pages/polls/admin-polls"], {
                        queryParams: {},
                    });
                    break;
                case "SUGGESTIONS":
                    this._router.navigate(
                        ["/pages/suggestions/admin-suggestions"],
                        {
                            queryParams: {},
                        }
                    );
                    break;
                case "FEEDBACK":
                    this._router.navigate(["/pages/feedback/admin-skills"], {
                        queryParams: {},
                    });
                    break;
                case "COURSES":
                    this._router.navigate(["/pages/education/admin-courses"], {
                        queryParams: {},
                    });
                    break;
                default:
                    break;
            }
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: "fuse-backdrop-on-mobile",
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay
                .position()
                .flexibleConnectedTo(
                    this._notificationsOrigin._elementRef.nativeElement
                )
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: "start",
                        originY: "bottom",
                        overlayX: "start",
                        overlayY: "top",
                    },
                    {
                        originX: "start",
                        originY: "top",
                        overlayX: "start",
                        overlayY: "bottom",
                    },
                    {
                        originX: "end",
                        originY: "bottom",
                        overlayX: "end",
                        overlayY: "top",
                    },
                    {
                        originX: "end",
                        originY: "top",
                        overlayX: "end",
                        overlayY: "bottom",
                    },
                ]),
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateUnreadCount(): void {
        let count = 0;

        if (this.notifications && this.notifications.length) {
            count = this.notifications.filter(
                (notification) => !notification.read
            ).length;
            this.unreadCount = count;
        }
    }
}
