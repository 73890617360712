import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { ApiService } from './api.service';
import {JwtService} from './jwt.service';
import { User } from '../models';
import { Location } from '@angular/common';
import { map } from 'rxjs/operators';

/**
 * The service offers the functionality to interact with Benio api's User endpoints 
 */
@Injectable()
export class UserService {
  usersDataSubject: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
  
  private currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>(new User());
  public currentUser = this.currentUserSubject.asObservable().distinctUntilChanged();

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  private isAdminSubject = new ReplaySubject<boolean>(1);
  public isAdmin = this.isAdminSubject.asObservable();

  /*private isGestorSubject = new ReplaySubject<boolean>(1);
  public isGestor = this.isGestorSubject.asObservable(); */

  constructor (
    private apiService: ApiService,
    private jwtService: JwtService,
    private _location: Location
  ) {}

  // Verify JWT in localstorage with server & load user's info.
  // This runs once on application startup.

  populate() {
   // If JWT detected, attempt to get & store user's info
   if (this.jwtService.getToken() && this._location.path() != '' && this._location.path() != '/pages/auth/login') {
     console.log("ILZ inside userService, populate, got token");
      // utilize our newly created get() method (params are optional)
      this.apiService.get('/user')
      .subscribe(        
        (data) => {
          this.setAuth(data.user)
        },
        (err) => {   
          this.purgeAuth()
        }
      );
    } else {
      // Remove any potential remnants of previous auth states
      this.purgeAuth();
    }
  }

  setAuth(user: User) {
    // set current user token
    this.jwtService.saveToken(user.token);
    // Set current user data into observable
    this.currentUserSubject.next(user);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);

    this.isAdminSubject.next(this.getCurrentUserIsAdmin());
    // this.isAdminSubject.next(this.getCurrentUserIsGestor());
  }

  purgeAuth(){
    console.log("ILZ inside userService, purgeAuth");
     // Remove JWT from localstorage
    this.jwtService.destroyToken();
    // Set current user to an empty object
    this.currentUserSubject.next(new User());
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
    this.isAdminSubject.next(false);
//    this.isGestorSubject.next(false); */
  }

  attemptAuth(user): Observable<User> {
    this.apiService.setAppApiUrl(); 
    return this.apiService.post('/user/login', {user: user})
    .map(
      data => {
        this.setAuth(data.user);
        return data.user;
      },
      err => {
        return err;
      }
    );
  }

  get data(): User[] {
    return this.usersDataSubject.value;
  }

  /**
   * Return a User by id, Users must be previously loaded using getUsers
   * @param id 
   */
  getUserById(id:string):User{
    for(var i=0;i<this.usersDataSubject.getValue().length;i++){
      if(this.usersDataSubject.getValue()[i]._id==id) return this.usersDataSubject.getValue()[i];
    }
    return undefined;
  }

  getCurrentUser(): User{
    return this.currentUserSubject.value;
  }
  
  getCurrentUserIsAdmin(): boolean{
    let isAdmin=false;
    if (this.getCurrentUser().roles !==undefined) isAdmin=this.getCurrentUser().roles.includes("ADMIN");
    
    //TEMP
    //TODO DELETE
    //isAdmin=true;

    return isAdmin;
  }

  getCurrentUserIsGestor(): boolean{
    let isGestor=false;
    if (this.getCurrentUser().roles !==undefined) isGestor=this.getCurrentUser().roles.includes("GESTOR");
    return isGestor;
  }

  /**
   * Set current used to the user associated with the provided email
   * @param userEmail 
   */
  setCurrentUserByEmail(userEmail:string): Observable<User>{
    //if(userEmail===undefined) return;
    this.apiService.setAppApiUrl();
    return this.apiService.get('/user/'+userEmail)
    .map(data => {
        this.setAuth(data.user);
        return data;
      }
    );
  }

  /**
   * The endpoint used by the service returns the existing users
   */
  getUsers(): void {
    this.apiService.setAppApiUrl();
    this.apiService.get('/users').subscribe(data => {
      this.usersDataSubject.next(data);
    },
    (err) => {
      console.log(err);
    });
  }

  getAllUsers(params?) {
    return this.apiService.get(`/users`,params)
      .pipe(map(data => data));
  }
  
  getUsersSelectingFields(params) {
    return this.apiService.get(`/users?fields=`+params)
      .pipe(map(data => data));
  }  

  /**
   * The endpoint used by the service adds the provided user to the DB
   * @param user 
   */
  addUser(user): Observable<User> { 
    this.apiService.setAppApiUrl();       
    return this.apiService.post('/users',{user: user})  
    .map(data =>{ 
        return data.user;
      }
    ); 
  } 

  /**
   * The endpoint used by the service activated an user
   * @param token 
   */
  activateAccount(token:string) : Observable<any> {
    this.apiService.setAppApiUrl();       
    return this.apiService.put('/activate/'+token)  
    .map(data =>{ 
        return data;
      }
    );    
  }

  /**
   * The endpoint used by the service sends an account activation email
   * @param email 
   */
  sendInvitation(email:string) : Observable<any> {
    this.apiService.setAppApiUrl();       
    return this.apiService.put('/resend/'+email)
    .map(data=>{
        return data;
      },
    );    
  }

  /**
   * The endpoint used by the service just updates token information the pwd must actually be updated in Alfresco
   * @param token 
   */
  resetPassword(token:string) : Observable<any> {
    this.apiService.setAppApiUrl();       
    return this.apiService.put('/reset/'+token)  
    .map(data =>{ 
        return data;
      }
    );    
  }

  /**
   * The endpoint used by the service sends a password recovery email
   * @param email 
   */
  sendRecovery(email:string) : Observable<any> {
    this.apiService.setAppApiUrl();       
    return this.apiService.put('/send-recovery/'+email)
    .map(data=>{
        return data;
      },
    );    
  }

  /**
   * The endpoint used by the service deletes a user from the DB
   * @param userId 
   */
  deleteUser(userId:string): Observable<User> { 
    this.apiService.setAppApiUrl();       
    return this.apiService.delete('/user/'+userId)  
    .map(data =>{ 
        return data.user;
      }
    ); 
  } 

}