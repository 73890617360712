import { NgModule , Optional, SkipSelf} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpTokenInterceptor } from './interceptors/http.token.interceptor';
import { IconsModule } from './icons/icons.module';

import {
  ApiService,
/*   ArticlesService,
  AuthGuard, */
  CoursesService,
  ExpensesService,
  EnpsPollService,
  PayrollService,
  PollService,
  JwtService,
  BenefitsService,
  CompaniesService,  
  ProvidersService,  
  SuggestionService,
  SkillService,
  FeedbackService,
  UsersService,
  NotificationsService,
  ValuesService,
/*   ProfilesService,
  TagsService,
  UserService */
} from './services';


@NgModule({
  imports: [
    CommonModule,
    IconsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    ApiService,
/*     ArticlesService,
    AuthGuard, */
    CoursesService,
    ExpensesService,
    EnpsPollService,
    PollService,
    PayrollService,
    JwtService,
    BenefitsService,
    CompaniesService,    
    ProvidersService, 
    SuggestionService,
    SkillService,  
    FeedbackService, 
    UsersService,    
    NotificationsService, 
    ValuesService,
/*     ProfilesService,
    TagsService,
    UserService */
  ],
  declarations: []
})
export class CoreModule {
  /**
     * Constructor
     */
   constructor(
    @Optional() @SkipSelf() parentModule?: CoreModule
)
{
    // Do not allow multiple injections
    if ( parentModule )
    {
        throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
    }
}
 }
