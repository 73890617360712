import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import * as navigation from 'app/navigation/navigation'

@Component({
  selector: 'header-with-breadcrumb',
  templateUrl: './header-with-breadcrumb.component.html',
  styleUrls: ['./header-with-breadcrumb.component.scss']
})
export class HeaderWithBreadcrumbComponent implements OnInit {

  // For icon
  icon: any

  // Title of 2nd level
  title: any

  // Title of 3rd level
  detail: any

  // Detail description for 3rd level
  detailDescription: any

  // For previous link
  url: any

  // Text use on add new page
  detailTranslate: any

  // Get the level of breadcrumb
  @Input() breadcrumbLevel: any

  // Get the type current user
  checktype: any

  // Get the detailName for dashboard to detail page
  detailName: any
  
  // Get the field1, field2, field3, field4 for different style of description
  field1: any
  field2: any
  field3: any
  field4: any

  adminMenu: any = navigation.navigation[0].children

  userMenu: any = navigation.navigation[1].children

  startPageLink: any

  constructor(
    private _activatedRoute: ActivatedRoute
  ) { 

  }

  ngOnInit() {
    this.getQueryParams()
    this.checktype = localStorage.getItem('checktype')
  }

  getQueryParams() {
    this._activatedRoute.queryParams.subscribe(params => {
      this.icon = params.icon
      this.title = params.title
      this.detail = params.detail
      this.detailDescription = params.detailDescription
      this.url = params.url
      this.detailTranslate = params.detailTranslate
      this.detailName = params.detailName
      this.field1 = params.field1
      this.field2 = params.field2
      this.field3 = params.field3
      this.field4 = params.field4

      this.adminMenu.forEach(element => {
        if(element.url == this.url) {
          this.startPageLink = 'true'
        }
      })
  
      this.userMenu.forEach(element => {
        if(element.url == this.url) {
          this.startPageLink = 'false'
        }
      })

    })    

  }  

}
