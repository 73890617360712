export const locale = {
    lang: 'eus',
    data: {
        'TOOLBAR': {
            'USER_MENU': {
                'PROFILE': 'Editar perfil',
                'CONFIG': 'Configuración',
                'MY_DOCS': 'Mis documentos',
                'LOGOUT': 'Cerrar sesión'
            }
        },
        'USER_HOME': {
            'HELLO': 'Kaixo',
            'SITUTATION_SUMMARY': 'Zure egoera:'
        }  
    }  
};
