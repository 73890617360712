import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { map, switchMap, take} from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject'; 
import { Notification } from '../models/notification.model';




@Injectable()
export class NotificationsService{

  private _notifications: ReplaySubject<Notification[]> = new ReplaySubject<Notification[]>(1);

  constructor (
    private apiService: ApiService,
    private _httpClient: HttpClient,

  ) {}



  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for notifications
     */
     get notifications$(): Observable<Notification[]>
     {
         return this._notifications.asObservable();
     }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  //getAll
  getNotifications(): Observable<Notification[]> {
    return this.apiService.get(`/notifications`)
        .pipe(map(data => data.notifications));
  }

  //Get all notifications with params (limit of 10)
  getAllWithParams(params) {
    return this.apiService.get(`/notifications`, params)
      .pipe(map(data => data.notifications));
  }

  //getSingle notification
  getSingleNotification(notificationId) {
    return this.apiService.post(`/notifications/`+notificationId)
      .pipe(map(data => data.notifications));
  }

  //Post or Create
  createNotification(notificationId) {
    return this.apiService.post(`/notifications/`+notificationId)
      .pipe(map(data => data.notifications));
  }

  //Put or Update the read status of a notification (true/false)
  putNotification(notificationId,bodyParams) {
    return this.apiService.put(`/notifications/`+notificationId, bodyParams)
    .pipe(map(data => data.notifications));
  }

}
