import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { Payroll } from '../models';
import { map } from 'rxjs/operators';


@Injectable()
export class PayrollService {
  constructor (
    private apiService: ApiService
  ) {}

  getAll(): Observable<Payroll[]> {
    return this.apiService.get(`/payrolls`)
      .pipe(map(data => data.payrolls));
  }

  getSingle(params): Observable<Payroll[]> {
    return this.apiService.get(`/payrolls`, params)
      .pipe(map(data => data.payrolls));
  }
  
  getPayrollsDates(params): Observable<Payroll[]> {
    return this.apiService.get(`/payrolls/dates`,params)
      .pipe(map(data => data.payroll_dates));
  }
  
  getBenefitMaximum() {
    return this.apiService.get(`/benefits/getRfBenefitsMax`)
      .pipe(map(data => data));    
  }

  putPayrollsForecast() {
    return this.apiService.put(`/payrolls/forecast`)
      .pipe(map(data => data.payroll));
  }
  
  getPayrollsSimulation(params): Observable<Payroll[]> {
    return this.apiService.get(`/payrolls/simulation`, params)
      .pipe(map(data => data.payroll));
  }

  getPayrollSavings(params): Observable<Payroll[]> {
    return this.apiService.get(`/payrollSavings`, params)
      .pipe(map(data => data.payrollSavings));
  }
  
  putPayrollSavings(year): Observable<Payroll[]> {
    return this.apiService.put(`/payrollSavings?year=`+year)
      .pipe(map(data => data.payrollSavings));
  }
  
  getPayrollSavingsSimulation(params) {
    return this.apiService.get(`/payrollSavings/simulation`,params)
      .pipe(map(data => data.payrollSavings));
  }  

}
