import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable()
export class ValuesService {
  constructor (
    private apiService: ApiService
  ) {}
  
  getAllWithParams(params) {
    return this.apiService.get(`/values`,params)
      .pipe(map(data => data.values));
  }
  
  putValues(valueId, bodyParams) {
    return this.apiService.put(`/values/`+valueId, bodyParams)
      .pipe(map(data => data.value));
  }
  
  postValues(bodyParams) {
    return this.apiService.post(`/values`, bodyParams)
      .pipe(map(data => data.value));
  } 

  deleteValues(valueId) {
    return this.apiService.delete(`/values/`+valueId)
      .pipe(map(data => data));
  } 


}
