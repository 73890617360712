export class Payroll {
    _id: string;
    user: string;
    company: string;
    date: string;
    salary: number; 
    hours: number; 

    /**
     * Constructor
     *
     * @param expense
     */
    constructor(payroll?) {
        payroll = payroll || {};
        this._id = payroll._id || null;
        this.user = payroll.user || '';
        this.company = payroll.company || '';
        this.date = payroll.date || '';
        this.salary = payroll.salary || 0;
        this.hours = payroll.hours || 0;
    }
}


