import { EmailValidator } from "@angular/forms";

export const locale = {
    lang: "es",
    data: {
        "LIST_ERRORS.ERROR": "Error",
        DISABLED: "Deshabilitado",
        STATUS_TYPES: {
            ACCEPTED: "Aceptado",
            DONE: "Hecho",
            PENDING: "Pendiente",
            REJECTED: "Rechazado",
        },
        MONTH_TYPES: {
            JAN: "ENE",
            FEB: "FEB",
            MAR: "MAR",
            APR: "ABR",
            MAY: "MAY",
            JUN: "JUN",
            JUL: "JUL",
            AUG: "AGO",
            SEP: "SEP",
            OCT: "OCT",
            NOV: "NOV",
            DEC: "DIC",
        },
        PRIVACY_TYPES: {
            PUBLIC: "Público",
            PRIVATE: "Privado",
        },
        FREQUENCY_TYPES: {
            MONTHLY: "Mensual",
            QUARTERLY: "Trimestral",
            TRIANNUAL: "Trianual",
            BIANNUAL: "Semestral",
            ANNUAL: "Anual",
            EVERY2Y: "Cada 2 años",
            EVENTUAL: "Por evento",
            OCCASIONAL: "Puntual (una vez)",
        },
        POLL_CATEGORY_TYPES: {
            ENGAGEMENT: "Satisfacción y compromiso",
            EMPLOYEE_JOURNEY: "Experiencia empleado",
            BENEFITS: "Beneficios y condiciones",
            CULTURE: "Cultura empresa",
            MANAGEMENT: "Gestión",
            EMPLOYER_BRANDING: "Employer branding",
        },
        POLL_TYPES: {
            PERF_FROM_SUPER: "Evaluación de superior",
            PERF_FROM_LOWER: "Evaluación a superior",
            PERF_FROM_PEER: "Auto evaluación",
        },
        SKILL_CATEGORY_TYPES: {
            SOFT: "Competencia blanda",
            HARD: "Competencia técnica",
        },
        SKILL_GROUP_TYPES: {
            PERSO: "Básica personal",
            SOCIAL: "Básica social",
            EMOTION: "Gestión emocional",
            LEADER: "Liderazgo",
            WORK_GEN: "Genérica profesional",
            COMM: "Comunicación",
            TEAM: "Trabajo en equipo",
            LEARN: "Aprendizaje",
            DEMANDED: "Más demandado",
        },
        TOOLBAR: {
            USER_MENU: {
                PROFILE: "Editar perfil",
                CONFIG: "Preferencias",
                PASSWORD: "Contraseña",
                LOGOUT: "Cerrar sesión",
            },
        },
        FOOTER: {
            CONTACT: "Contacto",
            HELP: "Ayuda",
            HELP_HOW: "¿Cómo funciona Benio?",
            HELP_ADVANTAGES: "¿Qué te puede aportar Benio?",
            LEGAL: "Legal",
            PRIVACY: "Política de privacidad",
            COOKIES: "Política de cookies",
        },
        CONFIRM_DIALOG: {
            TITLE: "Confirmar",
            BUTTON_CONFIRM: "Confirmar",
            BUTTON_CANCEL: "Cancelar",
        },
        FORGOT_PWD: {
            TITLE: "RECUPERA TU CONTRASEÑA",
            EMAIL: "e-mail",
            EMAIL_MANDATORY: "e-mail es obligatorio",
            EMAIL_INVALID: "Introduzca un e-mail correcto",
            SEND: "ENVIAR ENLACE",
            BACK: "Vuelva al login",
        },
        LOCK: {
            TITLE: "SESIÓN BLOQUEADA",
            DESCRIPTION:
                "Ha estado mucho tiempo sin actividad. Introduzca contraseña para continuar.",
            USERNAME: "Nombre usuario",
            PWD: "Contraseña",
            PWD_MANDATORY: "Contraseña obligatoria",
            UNLOCK: "DESBLOQUEAR",
            BACK: "Vuelva al login",
        },
        LOGIN: {
            TITLE: "ACCEDE A TU CUENTA",
            EMAIL: "e-mail",
            EMAIL_MANDATORY: "e-mail es obligatorio",
            EMAIL_INVALID: "Introduzca un e-mail correcto",
            PWD: "Contraseña",
            PWD_MANDATORY: "Contraseña obligatoria",
            REMEMBER: "Recuérdame",
            FORGOT_PWD: "¿Contraseña olvidada?",
            LOGIN: "LOGIN",
            NOT_ACCOUNT: "¿No tienes cuenta?",
            NEW_ACCOUNT: "Crea una cuenta de administrador",
            CONTACT: "Contacta con nosotros",
            WRONG_PWD: "Credenciales incorrectas",
            OK: "OK",
        },
        CONFIRM_MAIL: {
            TITLE: "Confirma tu cuenta e-mail!",
            SENT: "Se ha enviado un e-mail de confirmación a",
            INBOX: "Verifique su correo y clique en el enlace de confirmación.",
            BACK: "Vuelva al login",
        },
        REGISTER: {
            TITLE: "CREA TU CUENTA ADMIN",
            NAME: "Nombre",
            NAME_MANDATORY: "Nombre obligatorio",
            EMAIL: "e-mail",
            EMAIL_MANDATORY: "e-mail es obligatorio",
            EMAIL_INVALID: "Introduzca un e-mail correcto",
            PWD: "Contraseña",
            PWD_MANDATORY: "Contraseña obligatoria",
            PWD2: "Contraseña (confirmación)",
            PWD2_MANDATORY: "Contraseña de confirmación obligatoria",
            PWD_MATCH: "Las contraseñas deben coincidir",
            ACCEPT_TERMS: "He leído y acepto",
            TERMS: "Términos y condiciones",
            CREATE: "CREAR",
            ACOUNT_ALREADY: "Ya tienes una cuenta?",
            LOGIN: "LOGIN",
        },
        RESET_PWD: {
            TITLE: "ESTABLECE LA CONTRASEÑA",
            EMAIL: "e-mail",
            EMAIL_MANDATORY: "e-mail es obligatorio",
            EMAIL_INVALID: "Introduzca un e-mail correcto",
            PWD: "Contraseña",
            PWD_MANDATORY: "Contraseña obligatoria",
            PWD2: "Contraseña (confirmación)",
            PWD2_MANDATORY: "Contraseña de confirmación obligatoria",
            PWD_MATCH: "Las contraseñas deben coincidir",
            RESET: "SIGUIENTE",
            BACK: "Vuelva al login",
            PASSWORD_DOES_NOT_MATCH: "Las contraseñas no coinciden",
        },
        USER_HOME: {
            HELLO: "Hola",
            SITUTATION_SUMMARY:
                "Esta es la situación del clima laboral de tu empresa:",
            IN: "En",
            POLLS: {
                TITLE: "queremos seguir mejorando nuestro ambiente laboral",
                LAST_POLLS: "Últimas encuestas",
                ENPS: "Índice de satisfacción",
                RESULTS_HAVE_IMPROVED: "Los resultados han mejorado",
                RESULTS_HAVE_WORSE: "Los resultados han empeorado",
                ENPS_INFO: "Resultados de la encuesta de satisfacción eNPS",
                ENPS_LINK: "+ info ¿Cómo mide Benio la satisfacción? ",
                NEED: "Necesitamos tu participación",
                GOTO_INFO:
                    'Puedes ver el detalle de las encuestas y consultar tus respuesta en la sección de "Encuestas"',
                GOTO: "Ir a encuestas",
            },
            SUGGESTIONS: {
                TITLE: "Propuestas de mejora",
                DESCRIPTION:
                    "Tus sugerencias de mejora y las votaciones de las propuestas que más valoran nos ayudan a mejorar entre todos. Algunos cambios tardan más de lo que nos gustaría.",
                GOTO: "Ir a propuestas",
                QUOTE: "Hazlo lo mejor que puedas hasta que sepas más. Cuando sepas más, hazlo mejor.",
                QUOTE_AUTHOR: "Maya Angelou",
            },
            FEEDBACK: {
                MOST_ACTIVE: "Compañeros más activos",
                TITLE: "Cultura del reconocimiento",
                GIVEN: "Tus reconocimientos",
                RECEIVED: "Recibidos",
                QUOTE: "Existe algo mucho más escaso, fino y raro que el talento. Es el talento de reconocer a los talentosos.",
                QUOTE_AUTHOR: "Elbert Hubbard",
                GOTO: "Ir a reconocimiento",
            },
            PAY: {
                TITLE: "Resumen de tu remuneración salarial",
                SALARY_TITLE: "Tu salario anual",
                SALARY_INFO:
                    "Salario bruto anual en euros. Clica en la tarjeta para ver tu salario.",
                SALARY_BUTTON: "VER SALARIO ANUAL",
                SAVINGS_TITLE:
                    "Lo que te estás ahorrando con tu plan de beneficios",
                SAVINGS_INFO:
                    "Puedes recibir hasta un %30 de tu sueldo en beneficios para ahorrar el IRPF.",
                SAVINGS_BUTTON: "OPTIMIZA TU NÓMINA",
                PAYROLL_TITLE: "¿Quieres entender mejor tu remuneración?",
                PAYROLL_INFO:
                    "Puedes consultar la simulación de la nómina de una manera fácil y visual.",
                PAYROLL_BUTTON: "CONSULTA TU REMUNERACIÓN",
            },
            BENEFITS: {
                TEXT: "En beneficios incluidos en tu nómina",
                HEALTH: "Salud",
                FAMILY: "Familia",
                RETIREMENT: "Jubilación",
                HEALTH_INSUR: "Seguro médico",
                NURSERY: "Guardería",
                EPSV_CIA: "EPSV empresa",
                EPSV_PRIV: "EPSV privada",
                ACC_INSUR: "Seguro de accidente",
                TRANSPORT: "Transporte",
                RISK_INSUR: "Seguro colectivo",
                RESTAURANT: "Restaurante",
                HELP: "¿Qué es esto?",
                BUTTON: "Conoce todos tus beneficios",
            },
            MARKET: {
                TITLE: "¿Cómo estás frente al mercado laboral?",
                TEXT: "Aparte de tu sueldo hay más condiciones a tener en cuenta para compararte con la competencia.",
                FLEXITIME_DATA: "35%",
                FLEXITIME_INFO:
                    "El porcentaje de empresas que ofrecen este tipo de flexibilidad a nivel nacional",
                HOURS_DATA: "-58h",
                HOURS_TITLE: "Horas trabajadas:",
                HOURS_INFO:
                    "Estas son el número de horas trabajadas frente a la media estatal",
                FORMATION_DATA: "7%",
                FORMATION_TITLE: "Formación:",
                FORMATION_INFO:
                    "Este es el porcentaje de empresas que ofrecen formación a nivel nacional",
                RF_DATA: "3%",
                RF_TITLE: "Retribución flexible:",
                RF_INFO:
                    "El porcentaje de empresas que cuenta con el modelo de retribución flexible",
                EDIT: "Editar",
                GOTO: "Conoce tus beneficios",
            },
            CIA: {
                TITLE: "puedes disfrutar de los siguientes beneficios propios",
                CIA1: "Comedor",
                CIA1_1: "Comedor propio subvencionado por la empresa",
                CIA2: "Formación",
                CIA2_1: "La formación de idiomas o universitaria está parcialmente subvencionada",
                CIA3: "Jornada empresa",
                CIA3_1: "El día de la empresa te pagamos la comida",
                CIA4: "Flexibilidad",
                CIA4_1: "Te ofrecemos posibilidad de teletrabajar",
                CIA5: "Parking",
                CIA5_1: "Invertimos en parking para que aproveches tu tiempo libre al máximo",
            },
            EXPENSES: {
                TITLE: "Tus gastos de este mes",
                CUMULATED:
                    "De momento estos son los gastos acumulados que has pasado.",
                GOTO: "CONSULTA TU HISTÓRICO DE GASTOS",
                NEW: "NUEVO GASTO",
            },
            EDUCATION: {
                TITLE: "Dispones además de una amplia oferta formativa",
                WE_HAVE_MORE_THAN: "Tenemos más de",
                COURSES_AT_YOUR_DISPOSAL: "cursos a tu disposición",
                ALL_BUTTON: "Ver todos los cursos",
                OF_COURSES: "de cursos",
            },
            COURSE: {
                REMEMBER_YOU_HAVE: "Recuerda que tienes",
                PENDING_COURSES: "cursos pendientes",
                COMPULSORY_COURSE: "CURSO OBLIGATORIO",
                CERTIFICATION_ASSURED: "Certificación asegurada",
                FREE_COURSE: "Curso gratuito",
                COST_TO_THE_COMPANY: "Coste para la empresa",
                SIGN_UP_FOR_THIS_COURSE: "APUNTATE A ESTE CURSO",
                SEE_COURSE: "VER CURSO",
                FREE_PLACES: "Plazas libres",
                OF_DURATION: "De duración",
                PAYMENT_COURSE: "Curso de pago",
                COST_TO_THE_EMPLOYEE: "Coste para el empleado",
                VIEW_ALL_MY_COURSES: "Ver todos mis cursos",
            },
        },
        COURSE_DETAIL: {
            ADVANTAGES_FOR_YOU: "Ventajas para ti",
            ADVANTAGES_FOR_THE_COMPANY: "Ventajas para la empresa",
            HR_REVIEW: "Reseña de RRHH",
            OPINIONS_ABOUT_THE_COURSE: "Opiniones sobre el curso",
            OPINION: "Opinión",
            TRAINER: "Formador/a",
            DIRECTION: "Dirección",
            SCHEDULE: "Horario",
            PRICE: "Precio",
            COURSE_EVALUATION: "Valoración del curso",
            OF: "de",
            RATINGS: "calificaciones",
            MANDATORY_TARGETED: "Apuntado obligatoriamente",
            SIGN_ME_UP_FOR_THE_COURSE: "Apuntarme al curso",
            UNSUBSCRIBE_FROM_THE_COURSE: "Desapuntarme del curso",
            SUCCESSFULLY_SIGNED_UP_COURSE:
                "Apuntado satisfactoriamente al curso",
            SUCCESSFULLY_UNSUBSCRIBED_FROM_COURSE:
                "Desapuntado satisfactoriamente del curso",
            COURSES_YOU_HAVE_SIGNED_UP_DESC:
                "Puede consultar los cursos a los que te has apuntado desde Inicio o desde la página de Formación",
            EDIT_COURSE: "Editar curso",
        },
        ADMIN_HOME: {
            TITLE: "Dashboard administrador",
            PAYROLLS: "Nóminas",
            PAY_INFO:
                "Consulta, exporta e importa los datos relacionados con las nóminas",
            DELAY: "20 días para cerrar Septiembre",
            GOTO_PAYS: "Gestionar nóminas",
            COMPANY: "Empresa",
            CIA_INFO: "Configura tus datos y perfil de empresa",
            GOTO_CIA: "Gestionar datos",
            EMPLOYEE: "Empleados",
            EMP_INFO: "Da de alta a empleados o gestiona sus datos",
            GOTO_EMP: "Gestionar empleados",
            BRANDING: "Employer Branding",
            BRANDING_INFO:
                "Configura las ventajas de tu empresa para crear Employer Branding",
            GOTO_BRANDING: "Gestionar beneficios",
            SATISFACTION: "Índice satisfacción",
            RESULT_GOOD:
                "Bien! Los resultados han mejorado desde la última encuesta. Todavía no son buenos pero vamos por el buen camino.",
            DETRACTORS: "Detractores",
            PASIVES: "Pasivos",
            PROMOTORS: "Promotores",
            GOTO_SATIS: "Analizar los resultados",
            POLLS: "Encuestas",
            LAST_POLLS: "Ultimas encuestas respondidas",
            RESULT_EVO: "Evolución resultados",
            SATIS_PERCENTAGE: "Porcentaje satisfacción (total encuestas",
            ANSWERED_TOTAL: "Total (normalizado)",
            GOTO_POLLS: "Ir a las encuestas",
            SUGGESTIONS: "Propuestas de los empleados",
            MOST_DEMANDED_SUGGESTIONS: "¿Qué es lo que piden?",
            VOTES: "Número votos",
            EMPTY_TEXT: "Todas las propuestas",
            GOTO_SUGGESTIONS: "Ir a las propuestas",
            FEEDBACK: "¿Cómo evoluciona vuestra cultura de reconomiento?",
            MOST_ACTIVE: "Empleados más activos",
            MOST_PASSIVE: "Más pasivos",
            FEEDBACK_EVO: "Evolución del reconocimiento",
            FEEDBACK_TOTAL: "Reconocimientos totales",
            GOTO_FEEDBACK: "Más información",
            NUM_ASKED: "Número empleados",
            GOTO_BENEFS: "Configurar beneficios",
            EXPENSES: "Gastos",
            ANNUAL_COST: "Gasto anual",
            MONTH_AVG: "Media mensual",
            ANNUAL_TOTAL: "Total anual",
            MONTH_SUMMARY: "Resumen mensual",
            MONTH_TOTAL: "Total",
            GOTO_EXPENSES: "Gestionar gastos",
            EDUCATION: "Formación",
            POPULAR: "¿Cuáles son los 10 cursos con más asistencia?",
            GOTO_COURSES: "Gestionar formación",
            MOST_DEMANDED_IN_COMPANIES:
                "¿Y los más demandados en las empresas?",
            MOST_DEMANDED_COURSES: "¿Cuáles son los cursos más demandados?",
            MOST_VALUED_IN_COMPANIES: "¿Y los más valorados en las empresas?",
            AVERAGE_RATING: "Valoración media (de 0-5)",
        },
        MY_FEEDBACK: {
            TAB1: "Reconocimientos",
            TITLE: "Reconocer el trabajo de tus compañeros es uno de los mayores regalos que puedes realizar",
            MY_RESULTS: "Tus resultados",
            GIVEN: "Tus reconocimientos",
            RECEIVED: "Recibidos",
            EXPLANATION:
                "Puedes reconocer pequeños logros de tus compañeros, lo mucho que saben de un tema concreta, un aspecto de su personalidad... ¡seguro que lo apreciarán! Los reconocimientos pueden ser públicos o anónimos. Tambén puedes asociar este reconocimiento con un aspecto de la cultura de vuesta empresa o un área de mejora.",
            GIVE_BUTTON: "Dar reconocimiento",
            RECEIVED_TITLE: "Reconocimientos recibidos",
            ICON: "Feedback",
            COMMENTER: "Quién",
            ANONYMOUS: "Anónimo",
            FEEDBACK: "Mensaje",
            PRIVACY: "Privacidad",
            PUBLIC: "Público",
            PRIVATE: "Privado",
            COLLEAGUE: "Selecciona compañero",
            GIVE_RECOGNITION: "Reconoce el trabajo de tus compañeros",
            VALUES: "Valores:",
            RECOGNITION: "Reconocimiento",
            SEND: "Enviar reconocimiento",
            ADITIONAL_INFO:
                "Selecciona adicionalmente los valores que representa",
            SELECT_VALUES: "Selecciona valores",
            TAB2: "Feedback de",
            SUPERVISED: "compañeros",
            INTERVIEW: "Entrevista de Feedback",
            OPORTUNITY:
                "Es una oportunidad para detectar áreas de desarrollo y alinear objetivos personales y organizacionales",
            CHECK_IN:
                'Podéis romper el hielo comenzando la entrevista con un "Check-in". ¿Cómo te sientes con estas cuestiones?',
            FEELING1: "Sentimiento general sobre el trabajo",
            FEELING2:
                "Sentimiento de estrés, salud y conciliación vida personal-laboral",
            FEELING3:
                "Sentimiento con el desarrollo profesional, personal y aprendizaje",
            FEELING4: "Sentimiento con las relaciones personales en el trabajo",
            FEELING5: "Sentimiento con los objetivos, tareas y proyectos",
            LAST_ACHIEVEMENT:
                "Logro más importante que has conseguido desde la última entrevista",
            POLL_RESULTS: "Evaluación de competencias",
            SKILL_TOTAL: "Total:",
            AVG: "Media",
            GOALS_TITLE: "Definamos el camino para seguir mejorando",
            REMEMBER:
                'Recuerda que los valores de la empresa son "Agilidad, Compromiso, Alegría, Respeto y Calidad"',
            REMEMBER2:
                ' y que el propósito es "Hacer felices y conscientes a la mayor número de personas posibles". ',
            REMEMBER3:
                'Los objetivos de la empresa a medio plazo son "Aumentar la rentabilidad", "Mantener los valores", "Aumentar clientes", "Mejorar servicio al cliente" y "Atraer el capital humano".',
            GOALS: "OBJETIVOS",
            GOAL: "¿Quieres definir algún objetivo?",
            NEED_COURSE: "¿Necesitas algún curso para cumplir estos objetivos?",
            ACTIONS: "Acciones realizadas para lograrlo",
            COMPETENCIES: "COMPETENCIAS OBJETIVO",
            COMPETENCY_DIALOG_TITLE: "Añadir competencia",
            SELECT_COMPETENCY: "Selecciona competencia que quieres desarrollar",
            SELECT_COURSE:
                "Selecciona el curso con el que desarrollar la competencia",
            ADD_COMPETENCY: "Añadir competencia",
            SET_COMPETENCIES: "Apuntarse a algún curso",
            SET_GOAL: "Añadir objetivo",
            GOAL_DIALOG_TITLE: "Añadir objetivo individual",
            SELECT_GOAL: "Selecciona el objetivo de la empresa",
            SET_OBJECTIVE:
                "Define el objetivo personal relacionado con el de empresa",
            ADD_GOAL: "Añadir objetivo",
        },
        ADMIN_FEEDBACK: {
            TAB1: "Cultura reconocimiento",
            FEEDBACK: "Resumen de vuestra cultura del reconocimiento",
            MOST_ACTIVE: "Empleados más activos",
            MOST_PASSIVE: "Más pasivos",
            FEEDBACK_EVO: "Evolución del reconocimiento",
            FEEDBACK_TOTAL: "Reconocimientos totales",
            SEARCH: "Buscar empleados",
            EMPLOYEE: "Empleado",
            FEEDBACK_GIVEN: "Reconocimientos a otros",
            FEEDBACK_RECEIVED: "Reconocimientos recibidos",
            UNIQUE_GIVEN: "Personas reconocidas",
            UNIQUE_RECEIVED: "Personas que le han reconocido",
            TAB2: "Desarrollo competencias",
            SKILL_TITLE:
                "Define la matriz de competencias para las evaluaciones",
            SKILL_SUBTITLE:
                "Te recomendamos que además de las competencias técnicas, incluyas competencias transversales para todos los trabajadores. También es recomendable añadir competencias transversales de liderazgo para los supervisores (hemos predefinido una primera tabla para facilitarte el trabajo).",
            SKILL: "Competencia",
            REQUIRED_LEVEL: "Nivel requerido",
            EVAL_LEVEL: "Nivel evaluado",
            DIALOG_TITLE: "Editar competencia",
            SKILL_CAT: "Categoría",
            SKILL_GROUPS: "Grupos de competencias",
            SKILL_NAME: "Nombre competencia",
            SKILL_LEVEL_TIP: "Definición de los niveles de competencia",
            TIP_EXAMPLE:
                "Nivel 1:   , Nivel 2:   , Nivel 3:  , Nivel 4:   , Nivel 5:",
            SKILL_USERS: "Trabajadores",
            SKILL_LEVEL: "Definir nivel todos",
            DEFINE: "Define el nivel de competencias requerido individualmente:",
            SAVE: "Guardar",
            CREATE: "Crear",
            DELETE: "Eliminar",
            CANCEL: "Cancelar",
            ACTIVE: "Activo",
            TYPE: "Tipo",
            SCHEDULE_ASSESMENTS: "Programar las evaluaciones",
            SURVEYEE: "Para",
            EVERY_SUPER: "Supervisores",
            EVERY_LOWER: "Todos con supervisor",
            FREQUENCY: "Frecuencia",
            DATE: "Envío",
            EDIT: "Editar",
            ACTIVATE: "Activar",
            DEACTIVATE: "Desactivar",
            ACTION: "Acción",
            EVALUATOR: "Evaluador",
        },
        POLL: {
            ANONYMOUS: "Anónima",
            ANONYMOUS_INFO:
                "La información se tratará de forma anónimizada, por lo que ningún miembro de la organización podrá conocer el origen de la respuesta",
            CONFIDENTIAL: "Confidencial",
            CONFIDENTIAL_INFO:
                "La información será visible estrictamente para los usuarios que lo necesiten. Por ejemplo: en el caso de una encuesta de feedback, por la persona a la que va dirigida el feedbak. En el caso de las encuestas de satisfacción tus respuestas se mostrarán agrupadamente a RRHH para que puedan conocer y mejorar tu satisfacción",
        },
        POLLS: {
            ANSWER_STRONG_DISAGREE: "Muy en desacuerdo",
            ANSWER_DISAGREE: "En desacuerdo",
            ANSWER_NEUTRAL: "Neutral",
            ANSWER_AGREE: "De acuerdo",
            ANSWER_TOTAL_AGREE: "Totalmente deacuerdo",
            YES: "Sí",
            ANSWER: "Respuesta",
            FOR: "Para",
            ALL_THE_EMPLOYEES: "Todos",
            SELECT_USERS: "Selecciona empleados",
            FREQUENCY: "Frecuencia",
            SEND: "Próximo envío",
            INMEDIATELY: "Inmediato",
            INITIAL_DATE: "Fecha primer envío",
            DIALOG_TITLE: "Editar opciones de envío",
            SAVE: "Guardar",
        },
        ADMIN_POLLS: {
            TITLE: "Encuestas",
            TAB1: "Encuestas activas",
            TAB2: "Plantillas",
            RESULTS: {
                TITLE: "Listado y resultado de las encuestas activas",
                SUBTITLE:
                    'El resultado de las encuestas suele ser un indicador importante para priorizar las áreas a mejorar. Recolecta información suficiente lanzando nuevas encuestas (pestaña "Plantillas") o programando el envío de estas encuestas.',
                SUBTITLE_INFO:
                    'Por defecto la encuesta "Satisfacción" y eNPS están activas ya que son 2 encuestas básicas y muy útiles para medir la satisfacción. Los resultados de algunas encuestas NO se muestran en esta pantalla: los resultados del eNPS se muestran en el menú "Fidelización empleados". Los resultados de las encuestas de competencias en el Rendimiento del menú "Gestionar empleados" ',
                SEARCH: "Buscar encuestas",
                DATE: "Fecha",
                POLL_NAME: "Encuesta",
                POLL_SUBTITLE: "Descripción corta",
                RESULTS: "Satisfechos",
                AVG: "Resultado",
                EVO: "Evolución",
                ANSWERS: "Respuestas",
                ACTIONS: "Acciones",
                SEE_DETAILS: "Ver detalle",
                POLLS: "Resumen encuestas",
                POLLS_INFO:
                    "La información sobre participación puede ser útil para planificar el envío de las encuestas. La evolución de los resultados para planificar vuestras iniciativas.",
                ANSWERED_EVO: "Evolución participación",
                ANSWERED_EVO_TOOLTIP:
                    "La gráfica contiene la participación en las encuestas eNPS y también las encuestas de competencias (encuestas de Feedback que no son reconocimientos)",
                ANSWERED_PERCENTAGE: "Porcentaje participación",
                ANSWERED_TOTAL: "Total (normalizado de 0-200)",
                ANSWERED_TOTAL2: "Encuestas totales respondidas",
                SATIS_EVO: "Evolución resultados",
                SATIS_EVO_TOOLTIP:
                    "La gráfica no contiene los resultados del eNPS",
                SATISFACTION: "Porcentaje satisfacción (total encuestas)",
                SATISFACTION2: "Porcentaje satisfacción",
            },
            RESULT: {
                OVERVIEW_TITLE: "Resumen de los resultados",
                INFO: "+info ¿Cómo medimos los resultados?",
                BAD: "Margen de mejora! El porcentaje de empleados satisfechos con el tema de la encuesta no es bueno.",
                REGULAR:
                    "¡Regular! Los resultados son aceptables, pero sería recomendable tomar medidas para aumentar el porcentaje de empleados satisfechos con el tema de la encuesta",
                GOOD: "El porcentaje de empleados satisfechos con el tema de la encuesta es bueno, sigue así o mejora para conseguir la excelencia",
                VERY_GOOD:
                    "Enhorabuena! El porcentaje de empleados satisfechos con el tema de la encuesta es excelente",
                AVG_PERSON: "Media empleados",
                SATISFIED: "Satisfechos",
                NEUTRAL: "Neutrales",
                UNSATISFIED: "Insatisfechos",
                ANSWERS: "Respuestas",
                ANSWER_RATIO: "Ratio de respuesta",
                ANSWERS_TITLE: "Resultados preguntas",
                AVG_QUESTIONS: "Resultado medio de las preguntas",
                CREATE_SUGGESTION: "Propuesta",
                IMPROVEMENTS:
                    "Propuestas de Benio para mejora para mejorar los resultados",
                CONVERT_SUGGESTION: "Convertir en propuesta",
            },
            MANAGE: {
                TITLE: "Plantillas para tus encuestas",
                FILTER: "Filtra por categoría:",
                ALL: "Todas",
                SUBTITLE:
                    "Recopilar información de tus empleados es vital para que puedas priorizar las áreas de mejora más críticas. Te recomendamos que tengas siempre activas las encuestas de Satisfacción y Experiencia del Empleado. Puedes valorar la utilización de las demás encuestas en base a tu propio criterio, sugerencias que te envíe Benio y los recursos que los que dispongas.",
                SUBTITLE_INFO:
                    'Las encuestas de Satisfacción y compromiso son encuestas generales que se suelen enviar periódicamente y permiten tener una visión global de lo que preocupa a tus empleados. Las encuestas de "Experiencia del empleado" se suelen enviar en base a eventos y sirven para mejorar vuestra gestión RRHH. El resto de encuestos son también muy importantes, pero, en general, necesitan una periodicidad menor y dependen del grado de madurez cultural o necesidad de cada empresa.',
                MINUTES: " min",
                USE_TEMPLATE: "Ver plantilla",
            },
            FROM_TEMPLATE: "Crear desde plantilla",
            EDIT: {
                TITLE: "Editar próximo envío",
                TEMPLATE_TITLE: "Contenido de la encuesta",
                CREATE_FROM_TEMPLATE: "Usar plantilla para crear encuesta",
                SAVE: "Guardar",
            },
        },
        MY_POLLS: {
            TITLE: "Tus respuestas hacen que podamos seguir mejorando",
            SUBTITLE1: "Tienes",
            SUBTITLE2: "encuestas pendientes de responder",
            ANSWERED_TITLE:
                "Gracias por tus respuestas, ¡nos ayudan a crecer juntos!",
            ANSWERED_SUBTITLE:
                "Aquí puedes ver el detalle de tus respuestas o el resultado general de las encuestas. Si el resultado de las encuestas es positivo se muestra el porcentaje de resultados positivos, en caso contrario, el porcentaje de negativos. En el caso de las encuestas eNPS el resultado es más exigente, ya que muestra el porcentaje de promotores (9-10) - porcentaje detractores (0-6).",
            ANSWER_BUTTON: "Responder",
            ANSWER_TITLE: "Responder encuesta",
            EDIT_BUTTON: "Ver o editar",
            EDIT_TITLE: "Tu encuesta",
            SAVE_BUTTON: "Guardar",
            GEN_RESULTS: "Resultados globales",
            INFO_GEN_RESULTS:
                "Porcentaje de personas satisfechas y número de respuestas",
            MY_RESULTS: "Tus respuestas:",
        },
        ADMIN_SUGGESTIONS: {
            TITLE: "Todas las propuestas",
            FILTER: "Filtrar propuestas",
            SOURCE: "Origen",
            VOTES: "Votos",
            SUGGESTION: "Sugerencia",
            UPDATED_AT: "Actualizado",
            CREATED_AT: "Creado",
            STATE: "Estado",
            CHARTS: "Responder a las propuestas de los empleados es una de las mejores manera de motivarlos",
            ANSWERS: "Estado de las propuestas",
            ANSWER_EVO: "Evolución propuestas",
            ANSWER_INFO: "Propuestas aceptadas, rechazadas o realizadas",
            DONE: "Propuestas realizadas",
            ACTION: "Acción",
            TO_REFUSE: "Rechazar",
            TO_PENDING: "Pendiente",
            TO_ACCEPT: "Aceptar",
            TO_DONE: "Hecho",
        },
        MY_SUGGESTIONS: {
            TITLE: "Ayudanos a priorizar las propuestas más interesantes",
            SUBTITLE:
                "Puedes votar las propuestas pendientes o hacer nuevas sugerencias",
            SOURCE: "Origen",
            VOTES: "Votos",
            PENDING_SUGGESTION: "Propuesta pendiente",
            SUGGESTION: "Propuesta",
            CREATED_AT: "Creado",
            STATUS_UPDATED_AT: "Tratado",
            STATE: "Estado",
            ADD: "Nueva propuesta",
            VOTE_TOOLTIP: "Votar propuesta",
            UNVOTE_TOOLTIP: "Dejar de votar",
            CHARTS: "Estado de las propuestas",
            ANSWERS: "Situación general",
            SEARCH: "Buscar propuesta concreta",
            ANSWER_EVO: "Evolución propuestas",
            ANSWER_INFO: "Propuestas aceptadas, rechazadas o realizadas",
            DONE: "Propuestas realizadas",
            NEW_MODAL_TITLE: "Añade una nueva sugerencia de mejora",
            ANONYMOUS: "Anónimo",
            NEW_SUGGESTION: "Escribe tu sugerencia",
            SEND: "Enviar",
        },
        // 'COMMON_SUGGESTIONS': {
        //     'STATUS_ACCEPTED': "Aceptada",
        //     'STATUS_PENDING': "Pendiente",
        //     'STATUS_REJECTED': "Rechazada",
        //     'STATUS_DONE': "Realizada",
        // },
        MY_PAYROLLS: {
            TITLE: "Nóminas",
            DOWNLOAD: "DESCARGAR NÓMINA",
            DISTRIBUTION: "Reparto de nómina:",
            DISTRI_DESC:
                "Te presentamos la nómina de una manera más intuitiva.",
            DISTRI_DESC_1:
                "Puedes simular las mejoras en tu remuneración directamente en el gráfico",
            DISTRI_DESC_2:
                "Ten en cuenta que esto es una simulación, no contamos con la información real de gastos, bonus u otros ingresos que puedas recibir",
            SAVINGS_CHART_TITLE: "Previsión anual de ahorro",
            SAVINGS_CHART_SIMULATION_TITLE: "Simulación anual de ahorro",
            YEAR: "AÑO",
            DOWNLOAD_ALL_YEAR: "DESCARGAR TODO EL AÑO",
            JANUARY: "Enero",
            FEBRUARY: "Febrero",
            MARCH: "Marzo",
            APRIL: "Abril",
            MAY: "Mayo",
            JUNE: "Junio",
            JULY: "Julio",
            AUGUST: "Agosto",
            SEPTEMBER: "Septiembre",
            OCTOBER: "Octubre",
            NOVEMBER: "Noviembre",
            DECEMBER: "Diciembre",
            BENEFIT_SETTINGS: "Configuración del beneficio",
            MONTH: "mes",
            SAVE_SETTING: "GUARDAR AJUSTE",
            APPLY_TO_NEXT_MONTHS: "Aplicar a próximos meses",
            PLEASE_ENTER_A_VALUE_BETWEEN:
                "Por favor, introduzca un valor entre",
            BACK_TO_FORECAST: "VOLVER A PREVISIÓN",
            TO_HIRE_THE_SIMULATED_BENEFITS_GO_TO:
                "Para contratar los beneficios simulados vaya a",
            MAXIMUM_ANNUAL_SAVINGS: "Ahorro anual máximo",
            CURRENT_ANNUAL_SAVINGS: "Ahorro anual actual",
            CURRENT_ANNUAL_SIMULATION: "Ahorro anual simulado",
            GROSS_COMPANY: "Bruto empresa",
            YOUR_BRUTE: "Tu bruto",
            CONTRIBUTION_TO_YOUR_EPSV: "Aportación a tu epsv",
            COMPANY_TAX: "Impuestos empresa",
            EPSV_COMPANY: "EPSV empresa",
            YOUR_NET: "Tu neto",
            YOUR_TAXES: "Tus impuestos",
            ENTRY: "Ingreso",
            EXPENSES: "Gastos",
            BENEFITS: "Beneficios",
            IRPF: "IRPF",
            SOCIAL_SECURITY: "Seguridad social",
            TRAINING: "Formación (con reducción irpf)",
            PENSION_PLAN: "Plan de pensiones",
            HEALTH_INSURANCE: "Seguro médico",
            ACCIDENT_INSURANCE: "Seguro accidente",
            RISK_INSURANCE: "Seguro de riesgo",
            NURSERY_CARD: "Tarjeta guardería",
            RESTAURANT_CHECK: "Cheque restaurante",
            SIMULATE: "Simular",
            DOWNLOAD_SIMULATION: "Descargar simulación",
            PAYROLL_SIMULATION_WITH_BENEFITS:
                "Simulación de Nómina con Beneficios",
            GO_TO_REQUEST_TRAINING: "Ir a pedir formación",
            COURSE_PRICE: "Precio del curso",
            DIALOG_HEAD_TEXT_FORMATION:
                "La formación relacionada con el puesto de trabajo tiene exención de IRPF si está incluída en tu nómina",
            GO_TO_HIRE: "Ir a contratar",
            CONFIGURATION_OF_THE: "Configuración del",
            EPSV_EMPLOYEE: "EPSV empleado",
            RESTAURANT_CARD: "Tarjeta de restaurante",
            DAYCARE_CHECK: "Cheque guardería",
            SIMULATE_ON_GRAPH: "Simular en Gráfico",
            SIMULATED_SAVINGS: "Ahorro simulado",
            EXPECTED_SAVINGS: "Ahorro previsto",
            MILEAGE: "Kilometraje",
            LOCOMOTION_TICKETS: "Tickets locomoción",
            STAY_TICKETS: "Tickets estancia",
            STAYS_WITHOUT_OVERNIGHT: "Estancias sin pernocta",
            STAYS_WITH_OVERNIGHT: "Estancias con pernocta",
            NO_FOREIGN_OVERNIGHT: "Sin pernocta extranjero",
            FOREIGN_NIGHTS: "Pernoctas extranjero",
            KM: "km",
            EXP_LOCO_KM: "expLocoKm",
            EXP_LIVING_NOT_OVER: "expLivingNotOver",
            EXP_LIVING_OVERNIGHT: "expLivingOvernight",
            EXP_LIVING_NOT_OVER_FOREIGN: "expLivingNotOverForeign",
            EXP_LIVING_OVER_FOREIGN: "expLivingOverForeign",
            DAYS: "dias",
            NIGHTS: "noches",
        },
        ADMIN_PAYS: {
            TITLE: "Gestionar nóminas",
            EXPORT_TITLE: "Exportar datos para nóminas",
            EXPORT_TEXT:
                "Puedes descargar todos los datos de tus empleados para generar las nóminas",
            EXPORT_BUTTON: "Exportar archivo",
            IMPORT_TITLE: "Importar nóminas",
            IMPORT_TEXT:
                "Importa las nóminas para cada que cada empleado pueda consultarlo en su carpeta",
            IMPORT_BUTTON: "Adjuntar archivo",
            SEARCH: "Busca una nómina",
            SEARCH_HELP: "Introduce una palabra",
            DOWN_BUTTON: "Descargar seleccionadas",
            EMPLOYEE: "Empleado",
            DATE: "Fecha nómina",
            COMPANY: "Empresa",
            SALARY: "Salario",
            HOURS: "Horas extra",
        },
        MY_BENEFITS: {
            TITLE: "Beneficios",
            INFO_TITLE:
                "Descubre todos los beneficios de los que te vas a poder aprovechar por trabajar en",
            IN_OUR: "En nuestra",
            INFO_TEXT:
                "nos preocupamos por tí y queremos ayudarte a que comprendas tu remuneración y coberturas. Las aportaciones para disfrutar de estas coberturas las haces tú y la empresa pagando Seguridad Social y Seguro privado.",
            REPORT_TITLE: "Informe de beneficios",
            REPORT_TXT:
                "¿Quieres estudiar con detenimiento el infome de los beneficios de trabajar en nuestra empresa?",
            REPORT_BUTTON: "Descargar Informe",
            HEALTH: "Salud",
            HEALTH_DESC:
                "Intentamos explicarte lo más detalladamente posible cómo estas cubierto en caso de enfermedad y accidente. Puedes mejorar tus coberturas en cualquier momento. Recuerda además que puedes ahorrar dinero en IRPF o porque hayamos negociado un acuerdo colectivo con una aseguradora privada.",
            HEALTH_QUOTE:
                '"La salud es la mayor posesión. La alegría es el mayor tesoro. La confianza es el mayor amigo."',
            HEALTH_QUOTE_AUTHOR: "Lao Tzu",
            FAMILY: "Familia y retribución flexible",
            FAMILY_DESC:
                "A diferencia de la tradicional retribución fija, en la que se cobra un sueldo, la flexible permite al empleado cobrar una parte en productos y servicios que tienen ventajas fiscales. Productos relacionados con la Familia, Salud, Jubilación, Formación relacionada con el puesto de trabajo u otros productos permiten disfrutar de importantes ventajas fiscales (hasta un máximo del 30% del sueldo).",
            FAMILY_QUOTE:
                '"Puede parecer difícil de creer, pero al igual que los músculos tienen la cualidad deestirarse, los sueldos flexibles otorgan la facultad de cobrar más con el mismo salario."',
            FAMILY_QUOTE_AUTHOR: "Lanbide",
            RETIREMENT: "Jubilación",
            RETIRE_DESC:
                "Existe mucha incertidumbre sobre las pensiones, nos preocupamos en explicarte tu coberturas en jubilación. También te damos la posibilidad de mejorar tu jubilación con la ventaja añadida de poder pagar menos impuestos en la declaración de la renta.",
            RETIRE_QUOTE:
                '"Está bien salir de la carrera de ratas, pero tienes que aprender a que te vaya bien con menos queso."',
            RETIRE_QUOTE_AUTHOR: "Gene Perret",
            OTHERS_CIA: "Beneficios propios de",
            OTHERS_CIA_DESC:
                "A continuación te presentamos las ventajas particulares de trabajar en nuestra empresa. Te valoramos y nos esforzamos cada día para que tus condiciones laborales sean las mejores posibles.",
            OTHERS_CIA_QUOTE:
                '"El talento debe ser visto como el ingrediente más indispensable para el éxito, pero el éxito también depende de cómo se gestiona ese talento."',
            OTHERS_CIA_QUOTE_AUTHOR: "Allan Schweyer",
            OTHERS: "Otros beneficios",
            OTHERS_DESC:
                "Otros beneficios sin beneficios fiscales que podrían venirte bien.",
            OTHERS_QUOTE:
                '"Si no puedes hacer grandes cosas, haz cosas pequeñas de una gran forma."',
            OTHERS_QUOTE_AUTHOR: "Napoleón Hill",
            OTHERS_PROD1_TITLE: "Tarjeta gimnasio",
            OTHERS_PROD1_DESC: "Precios ventajosos en gimnasios privados",
            OTHERS_PROD2_TITLE: "Pruebas deportivas",
            OTHERS_PROD2_DESC:
                "Descuentos en una selección de eventos deportivos",
            OTHERS_PROD_BUTTON: "Más información",
            HEALTH_INSUR: "Seguro médico",
            NURSERY: "Seguro médico",
            EPSV: "EPSV",
            ACC_INSUR: "Seguro de accidente",
            TRANSPORT: "Transporte",
            RISK_INSUR: "Seguro colectivo",
            RESTAURANT: "Restaurante",
            HELP: "¿Qué es esto?",
        },
        MY_BENEFIT_CARD: {
            COVERAGES: "Coberturas principales",
            SAVING: "Tu ahorro anual actual",
            MAX_SAVING: "Podrías ahorrar hasta",
            MORE_INFO: "Más información",
            PROFIT_ASSESSMENT: "Valoración del beneficio",
            OF: "de",
            RATINGS: "calificaciones",
        },
        ADMIN_BENEFITS: {
            TITLE: "Gestionar beneficios",
            BENEFIT_TITLE: "Beneficios para los empleados",
            RF_BASIC: "Productos básicos con beneficios fiscales",
            REST_CARD: "Tarjeta restaurante",
            SCHOOL: "Cheque guardería",
            EPSV: "EPSVs",
            ACC_INS: "Seguro accidente",
            RISK_INS: "Seguro de riesgo",
            PRODUCTS: "Productos sin beneficios fiscales a precios reducidos",
            MED_INS: "Seguro médico",
            GYM: "Tarjeta de gimnasio",
            RF_ADV: "Productos avanzados con beneficios fiscales",
            TECH: "Compra tecnología",
            CAR: "Compra coche",
            SAVE: "GUARDAR",
            MARKET: "Nuestra compañía frente al mercado",
            MARKET_H: "Horas anuales",
            ADVANTAGES: "Ventajas de nuestra empresa",
            //'BENEFITS_YOU_ALREADY_ENJOY_IN': 'Beneficios de',
            MARKET_TITLE:
                "¿Cómo están los empleados frente al mercado laboral?",
            MARKET_DESCRIPTION:
                "Configura la información si quieres que los empleados vean una comparativa simple de sus condiciones frente al mercado laboral. Los empleados siempre verán que disfrutan de formación por el simple hecho de utilizar Benio.",
            EDIT_MARKET_INFORMATION: "Editar información mercado",
            HOURS_YOUR_EMPLOYEES_WORK_PER_YEAR:
                "Horas que tus empleados trabajan al año",
            NUMBER: "Número",
            FLEXIBLE_SCHEDULE: "Flexibilidad horaria",
            ALL_THE_BENEFITS_OF_YOUR_COMPANY:
                "Todos los beneficios de tu empresa",
            ALL_THE_BENEFITS_OF_YOUR_COMPANY_DESCRIPTION:
                "Los beneficios con reducción en IRPF son los más valorados por los empleados. Se recomienda no desactivar estos beneficios.",
            NAME: "Nombre",
            BENEFIT: "Beneficio",
            ACTIVE: "Activo",
            OWNER: "Propietario",
            YOU: "TÚ",
            BENIO: "Benio",
            TYPE: "Tipo",
            SIMPLE: "Simple",
            DETAILED: "Detallado",
            REDUCTION_IRPF: "Reducción IRPF",
            ADVANTAGEOUS_PRICE: "Precio Ventajoso",
            NO_MONETARY: "No monetario",
            NUMBER_SHORT: "n°",
            SEARCH: "Buscar",
            NEW_BENEFIT: "Nuevo beneficio",
            VIEW_BENEFIT: "Ver beneficio",
            REMOVE: "Eliminar",
            EDIT: "Editar",
            ACTIVATE: "Activar",
            DEACTIVATE: "Desactivar",
            VAL: "Val.",
        },
        COMMON_MSG: {
            CLOSE: "Cerrar",
            CHANGES_SAVED: "Cambios guardados",
            UNABLE_TO_CONNECT: "Imposible conectar con el servicio",
            GEN_ERROR: "Ha ocurrido algún error",
            DOWNLOAD_FAILED: "Descarga fracasó",
            DOWNLOAD_SUCCESSFUL: "Descargar exitoso",
            SUCCESSFULLY_SAVE: "Guardar con éxito",
            SUCCESSFULLY_DELETE: "Eliminar con éxito",
            SAVE: "Guardar",
            NONE: "Ninguno",
            NO_DATA_FOUND: "Datos no encontrados",
            SUCCESSFULLY_SENT: "Enviado exitosamente",
            SUCCESSFULLY_UPDATE: "Actualización exitosa",
        },
        BENEFIT_DETAIL: {
            DETAILED_DESCRIPTION: "Descripción detallada del beneficio",
            CONFIGURE_CONTRIBUTION: "Configura tu aportación",
            CONFIGURE_CONTRIBUTION_DETAIL:
                "Detalle de la configuración de la aportación al beneficio",
            MONTHLY_INVESTMENT: "Inversión mensual",
            MONTHLY_INVESTMENT_DETAIL:
                "Aquí se muestra la cantidad mínima y máxima que el empleado puede aportar a este beneficio.",
            MONTHLY_INVESTMENT_DEFINE:
                "Aquí define la inversión mensual en este beneficio particular.",
            APPLICATION: "Aplicación",
            MONTHS_LIST_EMPLOYEE_ENJOY_BENEFIT:
                "Estos son los meses en los que el empleado puede disfrutar de este beneficio.",
            MINIMUM_INVESTMENT_AMOUNT: "Cantidad mínima de inversión",
            MAXIMUM_INVESTMENT_AMOUNT: "Cantidad máxima de inversión",
            WHAT_INCLUDES: "¿Qué incluye?",
            WHAT_IS_THE_EMPLOYEE_GOING_TO_EARN: "¿Qué va a ganar el empleado?",
            PROVIDER: "PROVEEDOR",
            VISIT_PROVIDER: "Visite los detalles del proveedor",
            ACTIVATE_BENEFIT: "Activar beneficio",
            EDIT_BENEFIT: "Editar beneficio",
            POSSIBLE_INVESTMENT_AMOUNTS: "Cantidades posibles de inversion",
            DEACTIVATE_BENEFIT: "Desactivar Beneficio",
            ANNUAL_MAXIMUM_SAVINGS: "Ahorro máximo anual",
            TAX_SAVINGS: "Ahorro en impuestos (IRPF)",
            SAVINGS_AT_FAVORABLE_PRICE: "Ahorro por precio ventajoso",
            CONTRACT: "Contratar",
            CHANGE_CONTRIBUTION: "Cambiar aportación",
            CONFIRMATION: "Confirmación",
            HIRE_OR_IMPROVE_BENEFIT:
                "Estás a punto de contratar o mejorar un beneficio",
            SUMMARY: "Resumen",
            APPLICATION_MONTHS: "Meses de aplicación",
            ANNUAL_INVESTMENT: "Inversión anual",
            CONFIRM_HIRING: "Confirmar contratación",
            CONTRACTED_BENEFIT: "¡Beneficio contratado!",
            CONTRACTED_BENEFIT_DESC:
                "En los próximos días te llegará vía email y correo postal el contrato del beneficio que acabas de contratar/mejorar.",
            OPINIONS_ABOUT_THE_BENEFIT: "Opiniones sobre el beneficio",
            OPINION: "Opinión",
            PLEASE_ENTER_A_VALUE_BETWEEN:
                "Por favor, introduzca un valor entre",
            MONTH: "mes",
            SELECT_AMOUNT: "Seleccione cantidad",
            PROFIT_ASSESSMENT: "Valoración del beneficio",
            OF: "de",
            RATINGS: "calificaciones",
        },
        BENEFIT: {
            BASIC_INFO: "Info básica",
            BENEFIT_NAME: "Nombre del beneficio",
            SAVE_SETTINGS: "Guardar configuración",
            MAIN_DESCRIPTION: "Descripción principal",
            DETAILED_DESCRIPTION: "Descripción detallada",
            BENEFIT_DESCRIPTION: "Descripción del beneficio",
            TYPE: "Tipo",
            DETAILED_BENEFIT: "Beneficio detallado",
            SIMPLE_BENEFIT: "Beneficio simple",
            BENEFIT: "Beneficio",
            REDUCTION_IRPF: "Reducción IRPF",
            ADVANTAGEOUS_PRICE: "Precio Ventajoso",
            NO_MONETARY: "No monetario",
            PROPERTY: "Propiedad",
            ADD_NEW: "Añadir nuevo",
            ICON: "Icono",
            NAME: "Nombre",
            DESCRIPTION: "Descripción",
            UPLOAD_IMAGE: "Subir imagen",
            FLEXIBLE_REMUNERATION_TYPE_BENEFIT:
                "Beneficio de tipo retribución flexible",
            MONTHLY_APPLICATION: "Aplicación mensual",
            MONTHLY_APPLICATION_DESCRIPTION:
                "El beneficio se podrá contratar por el empleado en los siguientes meses",
            REQUIRED: "Necesario",
            PLEASE_SELECT_ICON: "Por favor seleccione icono",
            SEARCH_ICON: "Buscar icono",
            WHAT_CONTRIBUTES_TO_THE_EMPLOYEE: "¿Qué aporta al empleado?",
            ADVANTAGE: "Ventaja",
            ADD_NEW_CONTRIBUTION: "Añadir nuevo aporte",
            INVESTMENT_TYPE: "Tipo de inversión",
            FIXED_FEES: "Cuotas fijas",
            CONFIGURABLE_VALUE: "Valor configurable",
            LOW: "Baja",
            HALF: "Media",
            HIGH: "Alta",
            FEE_NAME: "Nombre de la cuota",
            FEE_AMOUNT: "Cantidad de la cuota",
            ADD_NEW_QUOTA: "Añadir nueva cuota",
            NAME_FEE: "Nombre cuota",
            MINIMUM_VALUE: "Valor mínimo",
            MAXIMUM_VALUE: "Valor máximo",
            MAXIMUM_VALUE_HINT:
                "En el caso de que el valor máximo sea 0, no se aplicará ningún máximo.",
            PARTNER: "Partner",
            SPONSOR_NAME: "Nombre del patrocinador",
            INFORMATION_LINK: "Link de información",
            FILE_TOO_LARGE: "Archivo demasiado grande",
            SEARCH: "Buscar",
            NO_DATA_FOUND: "Datos no encontrados",
            PROVIDER: "Proveedor",
            SELECT_IMAGE: "Seleccionar imagen",
            PROPERTY_DESCRIPTION: "Descripción Propiedad",
            ADVANTAGE_DESCRIPTION: "Descripción Ventaja",
        },
        PROVIDER: {
            ADD_PROVIDER: "Agregar proveedor",
            NAME: "Nombre",
            WEBLINK: "Enlace web",
        },
        MY_EXPENSES: {
            TITLE: "Gastos",
            MONTH_SUMM: "Resumen mensual",
            ADD_TITLE: "Añadir gasto",
            CUMULATED:
                "De momento estos son los gastos acumulados que has pasado.",
            ADD_BUTTON: "Añadir nuevo gasto",
            WHEN: "¿Cuando me pagarán los gastos?",
            PENDING: "Pendiente",
            PAYROLL1: "Nómina de este mes",
            PAYROLL2: "Nómina siguiente mes",
            ALL: "Todos tus gastos:",
            CATEGORY: "Categoría",
            TYPE: "Tipo",
            CONCEPT: "Concepto",
            QUANTITY: "Cantidad",
            DATE: "Fecha",
            PAYING_DATE: "Previsión pago",
            STATUS: "Estado",
        },
        ADMIN_EXPENSES: {
            TITLE: "Gestionar gastos",
            EXPENSE_SUMM: "Resumen de gastos",
            ANNUAL_COST: "Gasto anual",
            ALL: "Todos los gastos:",
            SEARCH: "Busca un gasto",
            SEARCH_HELP: "Introduce una palabra",
            DOWN_BUTTON: "Descargar excel",
            CATEGORY: "Categoría",
            TYPE: "Tipo",
            CONCEPT: "Concepto",
            QUANTITY: "Cantidad",
            DATE: "Fecha",
            PAYING_DATE: "Previsión pago",
            STATUS: "Estado",
        },
        MY_COURSES: {
            TITLE: "Formación",
            NEXT: "Próximos cursos",
            NEXT_INFO:
                "Estos son todos los cursos a los que te has apuntado y a los que tendrás que asistir en los próximos días o ya estarás cursando. Accede a ellos para ver todos los detalles.",
            OTHER1: "¿Estás pensando el realizar algún curso?",
            // 'OTHER2': 'Ahorrate el IRPF',
            OTHER2: "Consúltanos",
            //'OTHER_INFO': 'La formación relacionada con tu actividad profesional está exenta de IRPF. Rellena el formulario y envíanos la información para que te ayudemos a aplicar las ventajas. La empresa pagará el importe de la formación y luego te lo descontaremos de tu nómina.',
            OTHER_INFO:
                "Sabemos que valoras mucho tu crecimiento personal y profesional. Queremos que crezcas con nosotros y haremos lo que podamos para que sea posible.",
            ADD: "PEDIR FORMACIÓN",
            SEARCH: "Busca un curso",
            SEARCH_HELP: "Introduce una palabra",
            ALL: "Todos los cursos",
        },
        COURSES: {
            ALL_COURSES: "Todos los cursos",
            SEARCH: "Buscar",
            ADD: "Nuevo curso",
            TITLE: "Título",
            COURSE_NAME: "Nombre curso",
            NAME: "Nombre",
            IMPORTANT_INFORMATION: "Información importante",
            DATE: "Fecha",
            TIME: "Hora",
            PLACE: "Lugar",
            PRICE: "Precio",
            MANDATORY: "Obligatorio",
            FREE: "Gratuito",
            NEW: "Nuevo curso",
            DETAIL: "Detalle del curso",
            ADD2: "AÑADIR",
            SAVE: "GUARDAR",
            SHORT_INFO: "Detalles del curso",
            CATEGORIES: "Categorías",
            PRICE_CIA: "Precio empresa",
            AUDIENCE: "Asistentes",
            INFO_PLUS: "Información adicional",
            ACTIVE: "Activo",
            VAL: "Val.",
            OFFERED_BY: "Ofrecido por",
            CAPACITY: "Aforo",
            SEE_COURSE: "Ver curso",
            REMOVE: "Eliminar",
            EDIT: "Editar",
            ACTIVATE: "Activar",
            DEACTIVATE: "Desactivar",
            EMPLOYEE_REQUESTS: "Peticiones de empleados",
            TRAINING: "Formación",
            EMPLOYEE: "Empleado",
            AMOUNT: "Importe",
            STATE: "Estado",
            SEE_REQUEST: "Ver petición",
            TO_ACCEPT: "Aceptar",
            TO_REFUSE: "Rechazar",
            TI: "TI",
            EDIT_COURSE: "Editar curso",
            NEW_OWN_FORMATION: "Nueva formación propia",
            NO_DE_PLAZAS: "N° de plazas",
            PROVIDE_CERTIFICATE: "Provee certificado?",
            SUBSIDIZED: "Subvencionado",
            SELECT_IMAGE: "Seleccionar imagen",
            REQUIRED: "Necesario",
            AMOUNT_SUBSIDIZED: "Cantidad subvencionada",
            THE_COMPANY_PAYS: "Paga la empresa",
            PAY_THE_EMPLOYEE: "Paga el empleado",
            PLACE_AND_TIME: "Lugar y horario",
            ADDRESS: "Dirección",
            LINK_GOOGLE_MAPS: "Link google Maps",
            DURATION_IN_HOURS: "Duración en horas",
            CONSECUTIVE_DATES: "Fechas consecutivas",
            NON_CONSECUTIVE_DATES: "Fechas no consecutivas",
            ONE_DAY: "Un día",
            ANOTHER_TYPE: "Otro tipo",
            START_DATE: "Fecha de inicio",
            END_DATE: "Fecha Fin",
            SCHEDULE: "horario",
            ADD_SCHEDULE: "Añadir horario",
            DETAILED_INFORMATION: "Información detallada",
            PROGRAM_SUMMARY: "Resumen de programa",
            EMPLOYEE_ADVANTAGES: "Ventajas para el empleado",
            COMPANY_ADVANTAGES: "Ventajas para la empresa",
            LINK_A_VIDEO: "Link a video",
            MANDATORY_PARTICIPANTS: "Participantes obligatorios",
            MANDATORY_SIGNED_UP: "Apuntado obligatoriamente",
            REQUEST_TRAINING_WITH_TAX_ADVANTAGES: "Pedir formación",
            COURSE_DETAILED_INFORMATION:
                "Añada la información más detallada posible sobre el formador, lugar, horario y razones por las que se solicita la formación",
            TOTAL_AMOUNT_OF_THE_COURSE: "Importe total del curso",
            SEND_REQUEST: "Enviar petición",
            CONFIRMATION: "Confirmación",
            COURSE_REQUEST_CONFIRMATION_MESSAGE:
                'Enviado satisfactoriamente. Recibirás la respuesta a tu petición y si el curso es aceptado lo verás como apuntado en la sección "Formación"',
            OK: "Ok",
        },
        ADMIN_SATISFACTION: {
            TITLE: "Medición de satisfacción",
            EVO_TITLE: "Evolución fidelización (encuesta eNPS)",
            EVO_INFO: "+ info ¿Cómo mide Benio la fidelización? ",
            EVO_SUBTITLE:
                "El eNPS es un método para medir la evolución de la fidelización de los empleados. La manera de medir los resultados es diferente al resto de nuestras encuestas.",
            EVO_SUBTITLE_INFO:
                "Al contener una única pregunta la tasa de participación del eNPS es alta por lo que es ideal para mandarlo frecuentemente. Sirve para evaluar cómo están funcionando nuestras iniciativas o identificar problemas a tiempo.",
            "6_MONTHS": "6 meses",
            "1_YEAR": "Año",
            "2_YEAR": "2 años",
            RESULT_TITLE: "Resultados de la última encuesta enviada",
            RESULT_GOOD:
                "Bien! Los resultados han mejorado desde la última encuesta. Todavía no son buenos pero vamos por el buen camino.",
            DETRACTORS: "Detractores",
            PASIVES: "Pasivos",
            PROMOTORS: "Promotores",
            SENT: "Enviados",
            ANSWER_RATIO: "Ratio respuesta",
            SHARE_THE_RESULTS: "Comparte los resultados",
            SHARE_THE_RESULTS_TEXT:
                "Es un ejercicio de transparencia, los empleados lo valorarán. Lo más relevante es compartir los resultados y elaborar un Plan de Acción con el feedback recibido.",
            SEND: "Envío informe resultados",
            SEND_TEXT:
                "Envía un informe al email de los empleados compartiendo los resultados",
            SEND_BUTTON: "Enviar informe",
            SCHEDULED_SURVEYS: "Encuestas programadas",
            ACTIVE: "Activado",
            FREQUENCY: "Frecuencia de envío",
            SURVEYEE: "Para",
            EVERYBODY: "Todos",
            NEXT_DATE: "Próximo envío",
            EDIT: "Editar",
            ACTIVATE: "Activar",
            DEACTIVATE: "Desactivar",
            EDIT_SCHEDULED_SURVEY: "Editar encuesta programada",
            FOR: "Para",
            ALL_THE_EMPLOYEES: "Todos los empleados,",
            EMPLOYEES: "empleados",
            AFFAIR: "Asunto",
            SUBJECT: "Sujeto",
            SHIPPING_OPTIONS: "Opciones de envío",
            DATE_TIME_OF_FIRST_SHIPMENT: "Fecha hora de primer envío",
            SHIPPING_FREQUENCY: "Frecuencia de envío",
            MONTHLY: "Mensual",
            QUARTERLY: "Trimestral",
            BIANNUAL: "Semestral",
            MONTHLY_FREQUENCY_FOR_COMPANIES:
                "La frecuencia Mensual suele ser la más apropiada para la mayoría de las empresas",
            SAVE_SETTINGS: "Guardar ajustes",
            LAST_REPORT_SENT_ON: "Último informe enviado el",
            EMPLOYEE_SATISFACTION_REPORT: "Informe satisfacción empleados",
            SHARE: "Comparte",
            SHARE_DESC:
                "Información que se compartirá con los empleados además del gráfico de satisfacción y los últimos resultados. Los ejercicios de transparencia son indispensables para mejorar el ambiente laboral.",
            REPORT_TITLE: "Título",
            MESSAGE_BODY: "Cuerpo del mensaje",
            SHARE_DESC_2:
                "Lo que más valoran los empleados es que te comprometas a una acción para mejorar la satisfacción. Ten en cuenta que los planes de acción deben de ser realistas y concisos",
            CHANGE_MESSAGE: "Cambiar mensaje",
            SEND_PROOF: "Enviar prueba",
            SEND_RESULTS: "Enviar resultados",
            SEND_RESULTS_TO_THE_FOLLOWING_EMAIL:
                "Enviar resultados al siguiente e-mail",
            SEND_EMAIL: "Enviar",
            EMAIL: "Email",
            RESULTS_REPORT: "Informe resultados",
            RESULTS: "Resultados",
            SUCCESSFULLY_SEND: "Enviar con éxito",
            FAILED_TO_SEND: "fallo al enviar",
            JAN: "ENE",
            FEB: "FEB",
            MAR: "MAR",
            APR: "ABR",
            MAY: "MAY",
            JUN: "JUN",
            JUL: "JUL",
            AUG: "AGO",
            SEP: "SEP",
            OCT: "OCT",
            NOV: "NOV",
            DEC: "DIC",
            ENPS_LESS_MINUS_TEN:
                "Margen de mejora! Los resultados no son buenos.",
            ENPS_BETWEEN_MINUS_TEN_TO_TWENTY:
                "Regular! La satisfacción de tus empleados es aceptable, pero sería recomendable tomar medidas",
            ENPS_BETWEEN_TWENTY_TO_FORTY:
                "La satisfacción de tus empleados es buena.",
            ENPS_GREATER_THAN_FORTY:
                "Enhorabuena! La satisfacción de tus empleados es excelente",
            RESULTS_HAVE_IMPROVED: "Los resultados han mejorado",
            RESULTS_HAVE_WORSE: "Cuidado! Los resultados han empeorado",
            SUCCESSFUL_SEND: "Envío exitoso",
        },
        ENPS_POLL: {
            TITLE: "Encuesta del clima laboral de tu empresa",
            HELP_US_TO_IMPROVE:
                "Nos encantaria conocer lo que piensas de tu empresa, nos ayudaria realmente a mejorar.",
            RECOMMEND_TO_A_FRIEND:
                "¿Del 0 al 10, cuánto recomendarías a un amigo trabajar en tu empresa?",
            SEND: "Enviar",
        },
        PROFILE: {
            TITLE: "Perfil de usuario",
            TAB_PROFILE: "Editar perfil",
            TAB_PREF: "Preferencias",
            TAB_DOCS: "Mis documentos",
            USER_DATA: "Información personal",
            USERNAME: "Usuario",
            EMAIL: "Email",
            ROLES: "Roles",
            NAME: "Nombre",
            LAST_NAME: "Primer apellido",
            LAST_NAME2: "Segundo apellido",
            SAVE: "GUARDAR",
            PWD_TITLE: "Cambiar Contraseña",
            OLD_PWD: "Contraseña actual",
            NEW_PWD: "Nueva contraseña",
            NEW_PWD2: "Repita nueva contraseña",
            FINAN_TITLE: "Información financiera",
            FINAN_INFO: "Información general",
            GROSSSALARY: "Salario bruto",
            BIRTH_DATE: "Fecha nacimiento",
            CIVIL_STATUS: "Estado civil",
            TAX_PROVINCE: "Provincia declaración de impuestos",
            PROF_CATEGORY: "Categoría profesional",
            ASCENDANTS: "Ascendientes a Cargo",
            NUM_OVER65: "Número ascendientes mayores de 65 a cargo",
            NUM_OVER75: "Número ascendientes mayores de 75 a cargo",
            CHILDREN: "Hijos",
            CHILD_BIRTH_DATE: "Fecha nacimiento descendiente",
            ADD_CHILD: "Añadir hijo",
            DEL_CHILD: "Eliminar hijo",
            LANGUAGES: "Idioma",
            LANG: "Idioma",
            NOTIFICATIONS: "Noitificaciones",
            CHANNEL: "Canal",
            FREQUENCY: "Frecuencia",
            EDIT_USER: "Editar usuario",
            PERSONAL_INFORMATION: "Datos personales",
            TAB_PASSWORD: "Contraseña",
            ADMIN: "ADMIN",
            USER: "USER",
            PASSWORD_DOES_NOT_MATCH: "Las contraseñas no coinciden",
            OLD_PASSWORD_DOES_NOT_MATCH: "La contraseña antigua no coincide",
            TAB_ENGAGEMENT: "Compromiso",
            ENGAGEMENT_TITLE: "Estimación del compromiso",
            ENGAGEMENT_INFO: "+ info ¿Cómo mide Benio el compromiso",
            ENGAGEMENT_LT_40:
                "¡Cuidado! El compromiso de tu empleado no es bueno. Evalua tomar medidas.",
            ENGAGEMENT_BETWEEN_40_60:
                "¡Regular! El compromiso de tu empleado es aceptable, pero sería recomendable tomar medidas",
            ENGAGEMENT_GT_60: "El compromiso de tu empleado es bueno.",
            ENGAGEMENT_GT_80: "El compromiso de tu empleado es excelente.",
            ENGAGEMENT_IMPROVED: "Los resultados han mejorado",
            ENGAGEMENT_WORSE: "¡Cuidado! Los resultados han empeorado",
            LAST_ENGAGEMENT: "Última encuesta satisfacción/compromiso",
            LAST_ENPSS: "Media encuestas eNPS semestre",
            LAST_FEEDBACK_NUM: "Feedbacks envíados semestre",
            LAST_SUGGESTION_NUM: "Sugerencias semestre",
            ENGAGEMENTE_HISTO: "Histórico del compromiso",
            ENGAGEMENT_HISTO_INFO:
                "La comparativa de participación ofrece una información valiosa sobre el compromiso del empleado respecto a sus compañeros. La evolución del compromiso ofrece el histórico total del empleado desde que empezó a usar de la herramienta",
            COMPARISON: "Comparativa con otros compañeros",
            ENGAGEMENT_EVO: "Evolución del compromiso",
            TAB_DEV: "Desarrollo competencias",
            DEV_TITLE: "Evaluación competencias",
            DEV_INFO: "+ info ¿Cómo medir el desarrollo de tus empleados?",
            DEV_PERC:
                "Nota: la suma total de la evaluación de sus competencias y la suma total del nivel requerido en cada una de ellas. Expresado en porcentaje:",
            EVAL_PENDING:
                'Pendiente de realizar evaluación. Las evaluaciones se pueden programar en "Gestionar feedback -> Desarrollo competencias"',
            DEV_HISTO: "Histórico del desarrollo",
            DEV_HISTO_INFO:
                "Las competencias y el nivel requerido en cada una de ellas puede variar durante el tiempo. Por lo tanto, en las siguientes gráficas se muestra el porcentaje de competencias evaluado frente al requerido y también la suma de los niveles evaluados y requeridos. También el número de cursos realizado.",
            DEV_PERC_EVO: "Evolución del porcentaje de desarrollo",
            DEV_EVO: "Evolución del desarrollo",
            EVAL_REQ_EDU: "Competencias requeridas y evaluadas",
        },
        USERS: {
            TITLE: "Empleados",
            SEARCH: "Buscar",
            FILTER: "Filtros",
            ALL: "Todos los usuarios",
            PENDING: "Por aceptar invitación",
            NAME: "Nombre",
            SUPERVISOR: "Supervisor",
            NO_SUPERVISOR: "Ninguno",
            // 'EMAIL': 'e-mail',
            ENGAGEMENT: "Compromiso",
            ROLES: "Roles",
            PERFORMANCE: "Desarrollo",
            PERFORMANCE_PENDING_HELP: "Pendiente de realizar evaluación",
            INVITATION_STATUS: "Alta Benio",
            GROSSSALARY: "Salario bruto",
            NEW_USER: "Nuevo usuario",
            VIEW: "Ver detalle",
            DETIL: "D",
            EDIT: "Editar",
            REMOVE: "Eliminar",
            INVITATION: "Invitación Benio",
            PERFORMANCE_SEND: "Enviar evaluación rendimiento",
            PENDING_HELP: "Pendinte de aceptar la invitación",
        },
        COMPANY: {
            TITLE: "Empresa",
            BASIC_INFO: "Datos básicos",
            BENEFITS: "Beneficios",
            BILLING: "Suscripciones y pago",
            LEGAL: "Información jurídica",
            BUSINESS_NAME: "Razón social",
            CIF: "CIF",
            CIF_HELP: "Formato CIF: letra seguida de 8 números",
            ADDRESS_TITLE: "Dirección compañía",
            ADDRESS: "Dirección: calle, número, piso...",
            CITY: "Población",
            PROVINCE: "Provincia",
            PC: "Código Postal",
            CONTACT_INFO: "Información de contacto",
            VALUES: "Misión, Visión y Valores",
            MISION: "Misión",
            VISION: "Visión",
            VALUES_CIA: "Valores de la empresa",
            NEW_VALUE: "Nuevo valor",
            DUPLICATED_VALUE: "El valor ya existe para la compañía",
            SURE: "¿Estás seguro de que los valores de la organización han cambiado?",
            TEL: "Número de teléfono",
            SAVE: "GUARDAR",
            CURRENT_PLAN_DESC: "Su tipo de suscripción es PREMIUM",
            BILLING_PERIOD: "al mes por empleado",
            CHANGE_PLAN: "Cambiar al Plan",
            CURRENT_PLAN: "Plan actual",
            PLAN1_TITLE: "BASIC",
            PLAN1_DESCR:
                "Para empresas que quieren empezar a trabajar su cultura",
            PLAN1_PRICE: "2",
            PLAN1_PROP1: "Encuestas eNPS",
            PLAN1_PROP2: "Recursos y gestión formativa",
            PLAN1_PROP3: "Integración mediante API",
            PLAN1_PROP4: "Soporte online",
            PLAN1_NOTE: "Pídenos una demo",
            PLAN2_TITLE: "ENTERPRISE",
            PLAN2_DESCR: "Para empresas centradas en las Personas",
            PLAN2_PRICE: "3",
            PLAN2_PROP1: "Funciones BASIC",
            PLAN2_PROP2: "Encuestas inteligentes",
            PLAN2_PROP3: "Propuestas mejora",
            PLAN2_PROP4: "Cultura de empresa",
            PLAN2_PROP5: "Rendimiento empleados",
            PLAN2_PROP6: "Employee Branding",
            PLAN2_PROP7: "Soporte telefónico",
            PLAN2_NOTE: "Pídenos una demo",
            PLAN3_TITLE: "PREMIUM",
            PLAN3_DESCR: "Beneficios al empleado",
            PLAN3_PRICE: "5",
            PLAN3_PROP1: "Funciones ENTERPRISE",
            PLAN3_PROP2: "Simulación empleados",
            PLAN3_PROP3: "Employee Branding ++",
            PLAN3_PROP4: "Retribución flexible",
            PLAN3_PROP5: "Otros beneficios salariales",
            PLAN3_NOTE: "Pídenos una demo",
            FAQ_TITLE: "Preguntas Frecuentes",
            FAQ1: "¿Puedo cancelar mi plan?",
            ANSWER1:
                "Puedes cancelar tu plan en cualquier momento, contáctanos por teléfono o email, tu cuenta seguirá activa hasta el final de mes y no te volveremos a facturar.",
            FAQ2: "¿Están seguros mis datos?",
            ANSWER2:
                "La seguridad de tus datos es tan importante para nosotros como lo es para tí. Por eso, guardamos tus datos en servidores con la más alta seguridad y frecuente actualización. Realizamos copias de seguridad y como se detalla en nuestra política de privacidad, puedes pedirnos tus datos en cualquier momento. Por supuesto cumplimos con la LGPD.",
            FAQ3: "¿Puedo obtener descuentos?",
            ANSWER3:
                "Puedes obtener descuentos pagando anualmente. Contáctanos para más información.",
            FAQ4: "¿Alguna otra pregunta?",
            ANSWER4: "Contáctanos usando el formulario de esta página.",
            REMAINING_TRIAL_DAYS: "Número de días restantes de prueba",
            AUTO_RENEW_YOUR_SUBSCRIPTION:
                "días restantes para auto-renovar su suscripción",
            SALARY_INFORMATION: "Información salarial",
            HOURS_YOUR_EMPLOYEES_WORK_PER_YEAR:
                "Horas que tus empleados trabajan al año",
            FLEXIBLE_SCHEDULE: "Flexibilidad horaria",
            NUMBER_OF_PAYMENTS: "Número de pagas",
            OFFERS_EPSV: "Ofrece EPSV",
        },
    },
};
