import { Component, OnInit, Input, HostListener, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { TranslateService } from '@ngx-translate/core';
import { BenefitsService } from 'app/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractDialogComponent } from 'app/shared/components/benefit-detail/contract-dialog/contract-dialog.component';

import { DOCUMENT } from '@angular/common';

import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';

// Month button toggle value defined
enum MonthButtonToggle {
  january = '0',
  february = '1',
  march = '2',
  april = '3',
  may = '4',
  june = '5',
  july = '6',
  august = '7',
  september = '8',
  october = '9',
  november = '10',
  december = '11'      
}

@Component({
  selector: 'benefit-detail',
  templateUrl: './benefit-detail.component.html',
  styleUrls: ['./benefit-detail.component.scss']
})
export class BenefitDetailComponent implements OnInit {

  // Get the type current user
  @Input() userType: any

  // Query string parameters
  icon: any
  title: any
  url: any
  benefitId: any

  // Get config value from payroll page
  configVal: any
  
  // Get the breadcrumb level
  breadcrumbLevel: any

  monthButtonToggle = MonthButtonToggle

  // Editor configuration
  classicEditor = ClassicEditor
  classicEditorConfig = { 
    toolbar: [],
    image: {
      styles: ['full', 'alignLeft', 'alignRight'],
      toolbar: [ 'imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight' ]
    }     
  }

  benefitInfoData: any
  benefitInfoLoader: boolean
  benefitInfoVisible: boolean = false


  benefitsSavings: any = {
    savings: 0,
    savingsMax: 0
  }

  // Rf benefit max data
  rfBenefitMaxData: any  

  // Config min and config max value
  configMIN: any
  configMAX: any

  // Config amount Value
  configAmountValue: any

  // Sticky right bar
  stickyRightBar: boolean = false

  // Sticky right bar position fixed, set height and overflow scroll by hostlistener  
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.stickyRightBar = window.pageYOffset >= 250
  }
  
  // Benefit reviews data, Review form, loader and averageRating
  benefitReviewsData: any = []  
  addReviewForm: UntypedFormGroup
  addReviewLoader = false
  benefitAverageRating = 0
  
  // For configure your contribute
  benefitErrorMsg: boolean = false
  configMonthsDialog: any = []  

  // Select config amount by selectbox
  selectAmount: any

  // Contract button
  contractButton: any

  // Months that user can select unselect
  monthsListForSelectUnselect: any = []

  constructor(
    private _benefitsService: BenefitsService,
    private _snackBar: MatSnackBar,
    private _translateService: TranslateService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _matDialog: MatDialog,
    @Inject(DOCUMENT) private _document,
    private _formBuilder: UntypedFormBuilder
  ) {  
  }

  ngOnInit() {
    this.populateGetRfBenefitsMax()
    this.getQueryParams()
    this.populateGetBenefitReviews()

    this.addReviewFormInit()
    this.addDynamicCss()
  }

  benefitAmtChange(event) {    
    let inputVal = parseInt(event.srcElement.value)
    let benefitMax = parseInt(this.configMAX)
    let benefitMin = parseInt(this.configMIN)

    if(inputVal >= benefitMin && inputVal <= benefitMax) {
      this.benefitErrorMsg = false
    } else {
      this.benefitErrorMsg = true
    } 

    // Get savings simulation for get value of savings and savingsMax
    this.populateGetSavingsSimulation(this.configAmountValue, this.configMonthsDialog.length)    
  }

  // Check value is persenting or not in configMonths array. if persent disabled is false
  isDisabledMonth(monthValue) {
    let valueCheckExist = this.monthsListForSelectUnselect.indexOf(monthValue)
    if(valueCheckExist !== -1) {
      return false
    } else {
      return true
    }
  }
  
  updateBenefitSetting(event) {
    this.benefitErrorMsg = false
    this.configAmountValue = event.value
    // Get savings simulation for get value of savings and savingsMax
    this.populateGetSavingsSimulation(this.configAmountValue, this.configMonthsDialog.length)
  } 

  // Config amount by select box 
  selectBoxConfigAmount() {
    // Get savings simulation for get value of savings and savingsMax
    this.populateGetSavingsSimulation(this.configAmountValue, this.configMonthsDialog.length)
  }
  
  // Get savings simulation for get value of savings and savingsMax
  populateGetSavingsSimulation(amount, monthNum) {    
    this._benefitsService.getSavingsSimulation(this.benefitId, amount, monthNum).subscribe((response) => {
      if(response) {
        this.benefitsSavings.savings = response.savings
        this.benefitsSavings.savingsMax = response.savingsMax
      }
    }, (error) => {
      this.openSnackBar(this._translateService.instant('COMMON_MSG.UNABLE_TO_CONNECT')+' getSavingsSimulation')
    })   
  }  

  // Get benefit reviews
  populateGetBenefitReviews() {    
    this._benefitsService.getReviews(this.benefitId).subscribe((response) => {
      this.benefitReviewsData = response

      let ratingSum = 0

      // Calculate average rating
      this.benefitReviewsData.forEach(element => {
        if(element.rating) {
          ratingSum += element.rating
        } else {
          element.rating = 0
        }   
      }) 
      
      this.benefitAverageRating = (ratingSum/this.benefitReviewsData.length)

    }, (error) => {
      this.openSnackBar(this._translateService.instant('COMMON_MSG.UNABLE_TO_CONNECT')+' getBenefitReviews')
    })   
  }
  
  // Add review form initialisation
  addReviewFormInit() {
    this.addReviewForm = this._formBuilder.group({
      rating_input: new UntypedFormControl(''),
      opinion_text: new UntypedFormControl('')
    })
  }
  
  // Save benefit review
  saveBenefitReview(formData) {    
    this.addReviewLoader = true
    let params = {
      review: {
        body: formData.opinion_text,
        rating: formData.rating_input
      }
    }
    this._benefitsService.addReviews(this.benefitId, params).subscribe((response) => {      
      this.addReviewLoader = false
      // Clear review form
      this.addReviewForm.patchValue({
        rating_input: '',
        opinion_text: ''
      })
      this.populateGetBenefitReviews()
      this.openSnackBar(this._translateService.instant('COMMON_MSG.SUCCESSFULLY_SAVE'))
    }, (error) => {
      this.addReviewLoader = false
      this.openSnackBar(this._translateService.instant('COMMON_MSG.UNABLE_TO_CONNECT')+' saveBenefitReview')
    })    
  }   

  // Add dynamic css to the body and html for right bar sticky properties
  addDynamicCss() {
    this._document.documentElement.style.cssText = "height: auto; max-height: none; overflow: auto;"
    this._document.body.style.cssText = "height: auto; max-height: none; overflow: auto; overflow-x: hidden;"
  }

  // Remove dynamic added css 
  ngOnDestroy() {
    this._document.documentElement.style.cssText = "height: 100%; max-height: 100%; overflow: hidden;"
    this._document.body.style.cssText = "height: 100%; max-height: 100%; overflow: hidden;"
  }

  onValChange(val) {
    this.configMonthsDialog = val

    // Get savings simulation for get value of savings and savingsMax
    this.populateGetSavingsSimulation(this.configAmountValue, this.configMonthsDialog.length)
  }  

  // Contract dialog open
  contractDialogOpen() {
    // Condition for benefit hasFiniteConfigOptions is false
    if(!this.benefitInfoData.hasFiniteConfigOptions) {
      // Condition for ConfigAmount value is not greater than configMAX value
      if(this.configAmountValue > this.configMAX) {
        this.configAmountValue = this.configMAX
        this.benefitErrorMsg = false
        // Get savings simulation for get value of savings and savingsMax
        this.populateGetSavingsSimulation(this.configAmountValue, this.configMonthsDialog.length)        
      }

      // Condition for ConfigAmount value is not smaller than configMIN value
      if(this.configAmountValue < this.configMIN) {
        this.configAmountValue = this.configMIN
        this.benefitErrorMsg = false
        // Get savings simulation for get value of savings and savingsMax
        this.populateGetSavingsSimulation(this.configAmountValue, this.configMonthsDialog.length)        
      }      
    }
    const dialogRef = this._matDialog.open(ContractDialogComponent, {
      panelClass: 'contract-dialog',
      data: {
        benefitId: this.benefitId,
        configAmountValue: this.configAmountValue,
        monthButtonToggle: this.configMonthsDialog,
        savings: this.benefitsSavings.savings,
        savingsMax: this.benefitsSavings.savingsMax
      }            
    })

    dialogRef.afterClosed().subscribe(data => {
      // Data get from dialog and redirect to my benefits  
      if(data != undefined) {
        this.gotoMyBenefits()
      }
    })
  }  

  // Get current query params
  getQueryParams() {
    this._activatedRoute.queryParams.subscribe(params => {
      this.icon = params.icon,
      this.title = params.title,
      this.url = params.url,      
      this.benefitId = params.benefitId,
      this.breadcrumbLevel = params.breadcrumbLevel
      if(this.breadcrumbLevel == undefined) {
        this.breadcrumbLevel = '3'
      }
      this.configVal = params.configVal

      // Config amount value update when user come from payroll page
      if(this.configVal) {
        this.configAmountValue = this.configVal
      }
    })    
  }  

  // Get rf benefit max values
  populateGetRfBenefitsMax() {   
    this.benefitInfoLoader = true 
    this._benefitsService.getRfBenefitsMax().subscribe((response) => {
      this.rfBenefitMaxData = response
      this.populateGetBenefit()
    }, (error) => {
      this.benefitInfoLoader = false
      this.populateGetBenefit()
      this.openSnackBar(this._translateService.instant('COMMON_MSG.UNABLE_TO_CONNECT')+' getRfBenefitsMax')
    })    
  }  

  // Get benefit info
  populateGetBenefit() {    
    this._benefitsService.getSingleBenefit(this.benefitId).subscribe((response) => {
      this.benefitInfoData = response.benefit      
      
      // Config array value convert to string
      this.benefitInfoData.configMonths = this.benefitInfoData.configMonths.map(element => {
        return element.toString()
      })

      // List of months value asign to variable
      this.monthsListForSelectUnselect = this.benefitInfoData.configMonths

      // Config Months contract dialog selection
      this.configMonthsDialog = this.benefitInfoData.configMonths

      // modify finiteConfigOptions for showing comma seperated value with currency symbol
      this.benefitInfoData.finiteConfigOptionsModify = this.benefitInfoData.finiteConfigOptions.map(element => {
        return element.amount+'€'
      })


      // Call benefit config service based on benefit id for configAmountValue and monthselection 
      this._benefitsService.getBenefitConfig(this.benefitId).subscribe((response) => {

        // Storing monthsapplied value
        let monthsAppliedArrayUnique = response.benefitConfig.monthsApplied.map(element => {
          return element.toString()
        })      

        // Finding uniue months
        monthsAppliedArrayUnique = monthsAppliedArrayUnique.filter(val => !this.benefitInfoData.configMonths.includes(val))
        
        // Month applied config array value convert to string and asign to benefit config months
        this.benefitInfoData.configMonths = response.benefitConfig.monthsApplied.map(element => {
          return element.toString()
        })
        
        // Remove month
        monthsAppliedArrayUnique.forEach(element => {
          let index = this.benefitInfoData.configMonths.indexOf(element)
          if (index !== -1) {
            this.benefitInfoData.configMonths.splice(index, 1)
          }       
        })        
        
        // Config Months contract dialog selection
        this.configMonthsDialog = this.benefitInfoData.configMonths        

        // amount value and savings value asign
        this.configAmountValue = response.benefitConfig.amount

        // Config amount value update when user come from payroll page
        if(this.configVal) {
          this.configAmountValue = this.configVal
        }

        // Contract button value should be true
        this.contractButton = true

        // Get savings simulation for get value of savings and savingsMax
        this.populateGetSavingsSimulation(this.configAmountValue, this.configMonthsDialog.length)
      }, (error) => {    
        this.configAmountValue = 0    
        
        // Config amount value update when user come from payroll page
        if(this.configVal) {
          this.configAmountValue = this.configVal
        }        

        // Contract button value should be false
        this.contractButton = false        

        // Get savings simulation for get value of savings and savingsMax
        this.populateGetSavingsSimulation(this.configAmountValue, this.configMonthsDialog.length)
      })

      if(this.benefitInfoData.benefitType != 'taxReduction') {
        if(this.benefitInfoData.configMin) {
          this.configMIN = this.benefitInfoData.configMin
        } else {
          this.configMIN = 0
        }
        if(this.benefitInfoData.configMax) {
          this.configMAX = this.benefitInfoData.configMax
        } else {
          this.configMAX = 10000
        }
      } else {
        this.configMIN = 0
        
        // Call config max value based on benefit type
        this.configMaxValueBenefitType(this.benefitInfoData.taxReductionType)        
      }

      this.benefitInfoLoader = false
      this.benefitInfoVisible = true
    }, (error) => {
      this.benefitInfoLoader = false
      this.benefitInfoVisible = false
      this.openSnackBar(this._translateService.instant('COMMON_MSG.UNABLE_TO_CONNECT')+' getBenefit')
    })   
  }  

  // Savings max value based on benefit type is taxReduction
  savingsMaxValueBenefitType(taxReductionType) {
    if(taxReductionType == 'benHealthIns') {
      this.benefitsSavings.savingsMax = this.rfBenefitMaxData.benHealthIns_max
    }
    if(taxReductionType == 'benAccIns') {
      this.benefitsSavings.savingsMax = this.rfBenefitMaxData.benAccIns_max
    }
    if(taxReductionType == 'benRiskIns') {
      this.benefitsSavings.savingsMax = this.rfBenefitMaxData.benRiskIns_max
    }
    if(taxReductionType == 'benRestCard') {
      this.benefitsSavings.savingsMax = this.rfBenefitMaxData.benRestCard_max
    }
    if(taxReductionType == 'benKindergarden') {
      this.benefitsSavings.savingsMax = this.rfBenefitMaxData.benKindergarden_max
    }
    if(taxReductionType == 'benTransport') {
      this.benefitsSavings.savingsMax = this.rfBenefitMaxData.benTransport_max
    }
    if(taxReductionType == 'benEmplEpsv') {
      this.benefitsSavings.savingsMax = this.rfBenefitMaxData.benRestCard_max
    }
    if(taxReductionType == 'benRetirPlan') {
      this.benefitsSavings.savingsMax = this.rfBenefitMaxData.benRetirPlan_max
    }     
  }

  // Config max value based on benefit type is taxReduction
  configMaxValueBenefitType(taxReductionType) {
    if(taxReductionType == 'benHealthIns') {
      this.configMAX = this.rfBenefitMaxData.benHealthIns_max
    }
    if(taxReductionType == 'benAccIns') {
      this.configMAX = this.rfBenefitMaxData.benAccIns_max
    }
    if(taxReductionType == 'benRiskIns') {
      this.configMAX = this.rfBenefitMaxData.benRiskIns_max
    }
    if(taxReductionType == 'benRestCard') {
      this.configMAX = this.rfBenefitMaxData.benRestCard_max
    }
    if(taxReductionType == 'benKindergarden') {
      this.configMAX = this.rfBenefitMaxData.benKindergarden_max
    }
    if(taxReductionType == 'benTransport') {
      this.configMAX = this.rfBenefitMaxData.benTransport_max
    }
    if(taxReductionType == 'benEmplEpsv') {
      this.configMAX = this.rfBenefitMaxData.benRestCard_max
    }
    if(taxReductionType == 'benRetirPlan') {
      this.configMAX = this.rfBenefitMaxData.benRetirPlan_max
    }      
  }  

  // Update benefit status active/deactive
  updateBenefitStatus(isActiveValue) {
    let bodyParams = {
      benefit: {
        isActive: isActiveValue
      }      
    }    
    this._benefitsService.putBenefits(this.benefitId, bodyParams).subscribe((response) => {
      this.openSnackBar(this._translateService.instant('COMMON_MSG.CHANGES_SAVED'))      
      this.gotoAdminBenefits()
    }, (error) => {
      this.openSnackBar(this._translateService.instant('COMMON_MSG.UNABLE_TO_CONNECT')+' updateBenefit')
    })
  }

  // Admin benefits page redirect function
  gotoAdminBenefits() {
    this._router.navigate(['/pages/benefits/admin-benefits'], { 
      queryParams: { 
        icon: this.icon,
        title: this.title,
        url: this.url
      } 
    })          
  }
  
  // My benefits page redirect function
  gotoMyBenefits() {
    this._router.navigate(['/pages/benefits/my-benefits'], { 
      queryParams: { 
        icon: this.icon,
        title: this.title,
        url: this.url
      } 
    })          
  }  

  // Edit benefit page redirect function
  gotoEditBenefit() {
    this._router.navigate(['/pages/benefits/admin-benefits/benefit/edit'], { 
      queryParams: { 
        icon: this.icon,
        title: this.title,
        url: this.url,
        detailTranslate: 'BENEFIT_DETAIL.EDIT_BENEFIT',
        benefitId: this.benefitId
      } 
    })          
  }   
  
  openSnackBar(message: string) {
    let action = this._translateService.instant('COMMON_MSG.CLOSE')
    this._snackBar.open(message, action, {
      duration: 2000
    })
  }  


}
