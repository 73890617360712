import { Component,Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'benio-sm-info-card',
  templateUrl: './sm-info-card.component.html',
  styleUrls: ['./sm-info-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SmallInfoCardComponent implements OnInit {

  @Input('image')
  image: string;

  @Input('info1')
  info1: string; 

  @Input('info2')
  info2: string; 

  @Input('button')
  button: string; 

  @Input('action_link')
  action_link: string; 

  @Input('routerLinkUrl')
  routerLinkUrl: string;
  
  @Input('queryParamsUrl')
  queryParamsUrl: string;   

  constructor(
    private _router: Router
  ) { }

  ngOnInit() {
  }

  gotoActionLink(): void {    
    this._router.navigate([this.action_link]);
  }

}
