import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MarketEditDialogComponent } from 'app/shared/components/market-info/market-edit-dialog/market-edit-dialog.component';
import { BenefitsService, CompaniesService } from 'app/core';
import { TranslateService } from '@ngx-translate/core';

import {FLEX_SCHEDULE_TYPES} from '../../../shared/models/company.model'

@Component({
  selector: 'market-info',
  templateUrl: './market-info.component.html',
  styleUrls: ['./market-info.component.scss']
})
export class MarketInfoComponent implements OnInit {

  // Get the type current user
  @Input() userType: any  

  marketInfoData: any
  companyInfoData: any

  marketInfoLoader: boolean
  marketInfoVisible: boolean = false  

  companyId = localStorage.getItem('companyId')

  constructor(
    private _matDialog: MatDialog,
    private _benefitsService: BenefitsService,
    private _companiesService: CompaniesService,
    private _snackBar: MatSnackBar,
    private _translateService: TranslateService    
  ) { }

  ngOnInit() {
    this.populateGetMarketInfo()
    this.populateGetCompanies()
  }
  
  populateGetMarketInfo() {
    this.marketInfoLoader = true
    this._benefitsService.getMarketInfos().subscribe((response) => {
      this.marketInfoData = response
      this.marketInfoData.flexScheduleText=FLEX_SCHEDULE_TYPES.find(x=>x.value===response.flexScheduleType).viewValue;
      this.marketInfoLoader = false
      this.marketInfoVisible = true
    }, (error) => {
      this.marketInfoLoader = false
      this.marketInfoVisible = false
      this.openSnackBar(this._translateService.instant('COMMON_MSG.UNABLE_TO_CONNECT')+' getMarketInfos')
    })    
  }
  
  populateGetCompanies() {    
    this._companiesService.getCompanies(this.companyId).subscribe((response) => {
      this.companyInfoData = response.company
    }, (error) => {
      this.openSnackBar(this._translateService.instant('COMMON_MSG.UNABLE_TO_CONNECT')+' getCompanies')
    })    
  }  

  // Edit market dialog open
  editMarketDialogOpen() {
    const dialogRef = this._matDialog.open(MarketEditDialogComponent, {
      panelClass: 'market-edit-dialog',
      data: {
        employees_hours: this.companyInfoData.anualWorkingHours,
        flexible_schedule: this.companyInfoData.flexScheduleType
      }            
    })

    dialogRef.afterClosed().subscribe(data => {
      // Data get from dialog and populate update company info
      if(data != undefined) {
        this.updateCompanies(data)
      }
    })
  } 

  // Update company info and get updated market and company info
  updateCompanies(data) {
    this.marketInfoLoader = true
    let bodyParams = {
      company: {
        anualWorkingHours: parseInt(data.employees_hours),
        flexScheduleType: data.flexible_schedule
      }      
    }
    this._companiesService.putCompanies(this.companyId, bodyParams).subscribe((response) => {
      this.populateGetMarketInfo()
      this.populateGetCompanies()
      if(response) {
        setTimeout(() => {
          this.openSnackBar(this._translateService.instant('COMMON_MSG.CHANGES_SAVED'))
        }, 1000)
      }
    }, (error) => {
      this.marketInfoLoader = false
      this.marketInfoVisible = false
      this.openSnackBar(this._translateService.instant('COMMON_MSG.UNABLE_TO_CONNECT')+' updateCompanies')
    })    
  }  
  
  openSnackBar(message: string) {
    let action = this._translateService.instant('COMMON_MSG.CLOSE')
    this._snackBar.open(message, action, {
      duration: 2000
    })
  }    

}
