import { Component,Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatProgressBar } from '@angular/material/progress-bar';

@Component({
  selector: 'benio-md-list-card',
  templateUrl: './md-list-card.component.html',
  styleUrls: ['./md-list-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MediumListCardComponent implements OnInit {


  @Input('title')
  title: string; 

  @Input('imgType')
  imgType: string;

  @Input() cardList: any



  constructor() { }

  ngOnInit() {
  }


}
