import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable()
export class CompaniesService {
  constructor (
    private apiService: ApiService
  ) {}
  
  getCompanies(companyId:string,params?) {
    return this.apiService.get(`/companies/`+companyId,params)
      .pipe(map(data => data));
  }
  
  putCompanies(companyId, bodyParams) {
    return this.apiService.put(`/companies/`+companyId, bodyParams)
      .pipe(map(data => data));
  }

}
