import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { ApiService } from './api.service';
import { Company } from '../models';

/**
 * The service offers the functionality to interact with Benio api's Company endpoints 
 */
@Injectable()
export class CompanyService {

  constructor (
    private apiService: ApiService
  ) {}
  
  /**
   * Gets company info by its BD id
   * @param companyId
   */
  getCompany(companyId:string): Observable<Company> {
    this.apiService.setAppApiUrl();
    return this.apiService.get('/companies/'+companyId)
    .map(data => {
      return data.company;
    });
  }

  /**
   * Updates company info. Company's id can't be modified
   * @param company
   */
  updateCompany(company): Observable<Company> { 
    this.apiService.setAppApiUrl();       
    return this.apiService.put('/company',{company})  
    .map(data =>{ 
        return data.company;
    }); 
  } 

}