export class Skill {
    _id: string;
    company: string;
    category:string;
    groups:string[];
    name: string;
    levelTip: string;
    users: any[];
    /**
     * Constructor
     *
     * @param skill
     */
    constructor(skill?) {
        skill = skill || {};
        this._id = skill._id || null;
        this.company = skill.company || null;
        this.category = skill.category || null;
        this.groups = skill.groups || [skillGroupTypes.WORK_GENERIC];
        this.name = skill.name || null;
        this.levelTip = skill.levelTip || null;
        this.users = skill.users || [{}];
    }
}

export class UserSkills {
    filteredSkills:Skill[];//copy of its skill but limiting the skill info to itself (skill.users just himself)
    dateEvals:String[];
    sumEvals:number[]
    sumReqs:number[]
    percSkills: number[]
    colorPercs: string[]
    constructor(userSkills?) {
        userSkills=userSkills || {};
        this.filteredSkills = userSkills.filteredSkills || [];
        this.dateEvals= userSkills.dateEvals || [];
        this.sumEvals= userSkills.sumEvals || [];
        this.sumReqs= userSkills.sumReqs || [];
        this.percSkills= userSkills.percSkills || [];
        this.colorPercs= userSkills.colorPercs || [];
    }
}

export enum skillCategoryTypes {
    SOFT= 'SOFT',
    HARD= 'HARD'
}

export enum skillGroupTypes {
    PERSONAL= 'PERSO',
    SOCIAL= 'SOCIAL',
    EMOTION= 'EMOTION',
    LEADERSHIP= 'LEADER',
    WORK_GENERIC= 'WORK_GEN',
    COMMUNICATION= 'COMM',
    TEAMWORK= 'TEAM', 
    LEARNING= 'LEARN',
    MOST_DEMANDED= 'DEMANDED',
}

