import {SelectTypeData} from 'app/core';

export class Company {
    _id: string;
    businessName: string; 
    pricePlan: [PricePlanTypes];
    cif: string;
    address: string;
    city: string;
    province: string;
    postalCode: number;
    telNumber: number;
    flexScheduleType: string;
    anualWorkingHours:number;
}

export enum FlexScheduleTypes {
    'FLEX_SCHEDULE' = "flexSchedule",
    'SCHEDULE_CHANGE' = "scheduleChange",
    'NO_FLEXIBILITY' = "noFlexibility"
}

export enum PricePlanTypes{
    'FREE'= "free",
    'BENEFITS'= "benefits",
    'CULTURE'= "culture",
    'DEVELOPMENT'= "development"
}

export const FLEX_SCHEDULE_TYPES: SelectTypeData[]= [//TODO Se están usando los viewValues para el select y traducciones en el Frontend. Meter orden
    { value: FlexScheduleTypes.FLEX_SCHEDULE, viewValue: "Flexibilidad total"},
    { value: FlexScheduleTypes.SCHEDULE_CHANGE, viewValue: "Flexibilidad parcial"},
    { value: FlexScheduleTypes.NO_FLEXIBILITY, viewValue: "Sin flexibilidad horaria"}]

