export const locale = {
    lang: 'es',
    data: {
        'NAV': {
            //ADMIN                 
            'ADMIN': 'Admin',
            'ADMIN_DASHBOARD': 'Dashboard',
            'ADMIN_SATISFACTION': 'Fidelización empleados',
            'ADMIN_POLLS': 'Encuestas',
            'ADMIN_SUGGESTIONS': 'Propuestas mejora',
            'ADMIN_FEEDBACK': 'Gestionar feedback',
            //'ADMIN_PAYROLLS': 'Gestionar datos nómina',
            'ADMIN_BRANDING': 'Employer branding',
            'ADMIN_COURSES': 'Gestionar formación',
            'COMPANY': 'Gestionar empresa',
            'USERS': 'Gestionar empleados',
            //USER
            'USER': 'Usuario',
            'HOME': 'Inicio',
            'POLLS': 'Encuestas',
            'SUGGESTIONS': 'Propuestas mejora',
            'FEEDBACK': 'Feedback',
            'BENEFITS': 'Beneficios',
            //'EXPENSES': 'Gastos',
            'EDUCATION': 'Formación',
            'MY_PAYROLLS': 'Beneficios en nómina'
        }
    }
};
