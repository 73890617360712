import { Component,Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'benio-sm-card',
  templateUrl: './sm-card.component.html',
  styleUrls: ['./sm-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SmallCardComponent implements OnInit {

  @Input('number')
  number: number;

  @Input('info1')
  info1: string; 

  @Input('info2')
  info2: string; 

  @Input('helpLink')
  helpLink: string; 

  @Input('routerLinkUrl')
  routerLinkUrl: string;
  
  @Input('queryParamsUrl')
  queryParamsUrl: string;    

  constructor(
  ) { }

  ngOnInit() {
  }

}
