import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';

import {SharedModule } from './shared';
import {CoreModule} from './core/core.module'
import {ApiService} from 'app/shared/services/api.service';
import {JwtService} from 'app/shared/services/jwt.service';
import {UserService} from 'app/shared/services/user.service';
import {CompanyService} from 'app/shared/services/company.service';
import {AuthGuard} from 'app/shared/services/auth-guard.service';
import { AuthModule } from './auth/auth.module';

const appRoutes: Routes = [
    {
        path        : 'pages',
        loadChildren: () => import('./pages.module').then(m => m.PagesModule)
    },
    {
        path      : '**',
        redirectTo: 'pages/auth/login'
    }
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
        
        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,        

        // App modules
        LayoutModule,

        SharedModule,//TODO review if needed I don't think so with CoreModule
        CoreModule,
        AuthModule
    ],
    providers: [
        ApiService,
        JwtService,
        UserService,
        CompanyService,
        AuthGuard
    ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}
