import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {FLEX_SCHEDULE_TYPES} from '../../../../shared/models/company.model'

// Flexible type status

@Component({
  selector: 'app-market-edit-dialog',
  templateUrl: './market-edit-dialog.component.html',
  styleUrls: ['./market-edit-dialog.component.scss']
})
export class MarketEditDialogComponent implements OnInit {

  marketEditForm: UntypedFormGroup

  flexScheduleTypes = FLEX_SCHEDULE_TYPES

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _matDialogRef: MatDialogRef<MarketEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) {
  }

  ngOnInit() {
    this.marketEditForm = this.marketEditFormInit()
  }

  // Market edit form initialisation
  marketEditFormInit(): UntypedFormGroup {
    return this._formBuilder.group({
      employees_hours: [this._data.employees_hours],
      flexible_schedule: [this._data.flexible_schedule]
    })
  }

  // Close dialog and send form data to info page
  closeDialog(formData) {
    if(formData.value != undefined) {
      this._matDialogRef.close(formData.value)
    } else {
      this._matDialogRef.close()
    }
  }  

}
