import { Component,Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'benio-md-full-card',
  templateUrl: './md-full-card.component.html',
  styleUrls: ['./md-full-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MediumFullCardComponent {

  @Input('cardIcon')
  cardIcon: string;

  @Input('cardTitle')
  cardTitle: string;

  @Input('subtitle')
  subtitle: string;

  @Input('info1')
  info1: string; 

  @Input('info2')
  info2: string; 

  @Input('description')
  description: string; 

  @Input('linkText')
  linkText: string; 

  @Input('bottomNumber1')
  bottomNumber1: number; 

  @Input('progressColor')
  progressColor: string; 

  @Input('bottomInfo1')
  bottomInfo1: string; 

  @Input('bottomInfo2')
  bottomInfo2: string; 

  @Input('bottomIcon')
  bottomIcon: string; 

  @Input('routerLinkUrl')
  routerLinkUrl: string

  @Input('queryParamsUrl')
  queryParamsUrl: string


  constructor() { }

}
