import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class SuggestionService {
  constructor (
    private apiService: ApiService,
    private _translateService: TranslateService
  ) {}

  getAll() {
    return this.apiService.get(`/suggestions`)
      .pipe(map(data => data.suggestions));
  }

  getAllWithParams(params) {
    return this.apiService.get(`/suggestions`,params)
      .pipe(map(data => data.suggestions));
  }

  putSuggestions(suggestionId, bodyParams) {
    return this.apiService.put(`/suggestions/`+suggestionId, bodyParams)
      .pipe(map(data => data.suggestion));
  }
  
  postSuggestions(bodyParams) {
    return this.apiService.post(`/suggestions`, bodyParams)
      .pipe(map(data => data.suggestion));
  } 

  translateStatus(status:string){
    let translatedStatus=""
    switch (status) {
      case 'pending': translatedStatus=this._translateService.instant('STATUS_TYPES.PENDING'); break;
      case 'accepted': translatedStatus=this._translateService.instant('STATUS_TYPES.ACCEPTED'); break;
      case 'rejected': translatedStatus=this._translateService.instant('STATUS_TYPES.REJECTED'); break;
      case 'done': translatedStatus=this._translateService.instant('STATUS_TYPES.DONE'); break;
    } 
    return translatedStatus
  }

}
