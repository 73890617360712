import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable()
export class ProvidersService {
  constructor (
    private apiService: ApiService
  ) {}
  
  getProviders() {
    return this.apiService.get(`/providers`)
      .pipe(map(data => data.providers));
  }

  postProviders(bodyParams) {
    return this.apiService.post(`/providers`, bodyParams)
      .pipe(map(data => data.provider));
  }  

}
