import { CommonModule } from '@angular/common';
import { FlexLayoutModule,FlexModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { ShowAdminDirective} from './show-admin.directive';
import { ShowAuthedDirective} from './show-authed.directive';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { SmallCardComponent } from './components/card1/sm-card.component';
import { SmallInfoCardComponent } from './components/card2/sm-info-card.component';
import { LargeCardComponent } from './components/card3/lg-card.component';
import { MediumCardComponent } from './components/card4/md-card.component';
import { MediumListCardComponent } from './components/card5/md-list-card.component';
import { MediumFullCardComponent } from './components/card6/md-full-card.component';
import { ListErrorsComponent } from './components/list-errors/list-errors.component';
import { HeaderWithBreadcrumbComponent } from './components/header-with-breadcrumb/header-with-breadcrumb.component';

import { TranslateModule } from '@ngx-translate/core';
import { MarketInfoComponent } from './components/market-info/market-info.component';
import { MarketEditDialogComponent } from './components/market-info/market-edit-dialog/market-edit-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BenefitDetailComponent } from './components/benefit-detail/benefit-detail.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ContractDialogComponent } from './components/benefit-detail/contract-dialog/contract-dialog.component';
import { BenefitSideBarComponent } from './components/benefit-detail/benefit-side-bar/benefit-side-bar.component';

import { StarRatingModule } from 'angular-star-rating';

import {  TruncatePipe }   from './truncate.pipe';

import { ProviderAddDialogComponent } from './components/dialog/provider-add-dialog/provider-add-dialog.component';
import { ImageSelectDialogComponent } from './components/dialog/image-select-dialog/image-select-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        FlexModule,
        HttpModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        MatListModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatToolbarModule,
        MatProgressSpinnerModule,
        MatButtonToggleModule,
        MatSliderModule,
        MatProgressBarModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        CKEditorModule,
        StarRatingModule.forRoot()
    ],
    declarations: [
        ShowAdminDirective,
        ShowAuthedDirective,
        PageHeaderComponent,
        SmallCardComponent,
        SmallInfoCardComponent,
        LargeCardComponent,
        MediumCardComponent,
        MediumListCardComponent,
        MediumFullCardComponent,
        ListErrorsComponent,
        HeaderWithBreadcrumbComponent,
        MarketInfoComponent,
        MarketEditDialogComponent,
        BenefitDetailComponent,
        ContractDialogComponent,
        BenefitSideBarComponent,
        TruncatePipe,
        ProviderAddDialogComponent,
        ImageSelectDialogComponent
    ],
    exports: [
        ShowAdminDirective,
        ShowAuthedDirective,
        PageHeaderComponent,
        SmallCardComponent,
        SmallInfoCardComponent,
        LargeCardComponent,
        MediumCardComponent,
        MediumListCardComponent,
        MediumFullCardComponent,
        ListErrorsComponent,
        HeaderWithBreadcrumbComponent,
        MarketInfoComponent,
        BenefitDetailComponent,
    ]
})
export class SharedModule {}