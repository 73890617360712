import { Component,Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'benio-md-card',
  templateUrl: './md-card.component.html',
  styleUrls: ['./md-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MediumCardComponent {

  @Input('icon')
  icon: string;

  @Input('title')
  title: string;

  @Input('info1')
  info1: string; 

  @Input('info2')
  info2: string; 

  @Input('button')
  button: string; 

  @Input('action_link')
  action_link: string; 

  @Input('ctmQueryParams')
  ctmQueryParams: any;  

  constructor(
    private _router: Router
  ) { }


  gotoActionLink(): void {
    if(this.ctmQueryParams != undefined) {
      this._router.navigate([this.action_link], { 
          queryParams:  {
            icon: this.ctmQueryParams.icon,
            title: this.ctmQueryParams.translate,
            url: this.ctmQueryParams.url
          }
        }
      );
    } else {
      this._router.navigate([this.action_link]);      
    }
  }

}
