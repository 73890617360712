import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Platform } from "@angular/cdk/platform";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { FuseConfigService } from "@fuse/services/config.service";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";

import { navigation } from "app/navigation/navigation";
import { UserService } from "app/shared/services/user.service";

import { locale as navigationSpanish } from "app/navigation/i18n/es";
import { locale as navigationEuskera } from "app/navigation/i18n/eus";
import { CompanyService } from "./shared/services/company.service";
import { PricePlanTypes } from "./shared";

@Component({
    selector: "app",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private _userService: UserService,
        private _companyService: CompanyService
    ) {
        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register("main", this.navigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation("main");

        // Add languages
        this._translateService.addLangs(["es", "eus"]);

        // Set the default language
        this._translateService.setDefaultLang("es");

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(
            navigationSpanish,
            navigationEuskera
        );

        // Use a language
        this._translateService.use("es");

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         **/

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add("is-mobile");
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._userService.populate();

        //ILZ not the best solution but, made this way not to change src/@fuse code
        this._userService.isAdmin
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((isAdmin) => {
                let checktype: any = isAdmin;
                localStorage.setItem("checktype", checktype);
                //Hide or show admin menus
                this._fuseNavigationService.updateNavigationItem(
                    "admin-pages",
                    {
                        hidden: !isAdmin,
                    }
                );
                //Hide menu items depending on company subscription
                this._companyService
                    .getCompany(this._userService.getCurrentUser().company)
                    .subscribe(
                        (response) => {
                            let pricePlans = response.pricePlan;
                            //TODO UPDATE AUTHGUARDS TAKING INTO ACCOUNT THE NEW PRICEPLANS
                            if (
                                isAdmin == true &&
                                pricePlans.includes(PricePlanTypes.CULTURE) ==
                                    false
                            )
                                this._fuseNavigationService.updateNavigationItem(
                                    "admin-polls",
                                    { hidden: true }
                                );
                            if (
                                isAdmin == true &&
                                pricePlans.includes(PricePlanTypes.CULTURE) ==
                                    false
                            )
                                this._fuseNavigationService.updateNavigationItem(
                                    "admin-suggestions",
                                    { hidden: true }
                                );
                            if (
                                isAdmin == true &&
                                (pricePlans.includes(PricePlanTypes.CULTURE) ==
                                    false ||
                                    pricePlans.includes(
                                        PricePlanTypes.DEVELOPMENT
                                    ) == false)
                            )
                                this._fuseNavigationService.updateNavigationItem(
                                    "admin-skills",
                                    { hidden: true }
                                );
                            if (
                                isAdmin == true &&
                                pricePlans.includes(PricePlanTypes.BENEFITS) ==
                                    false
                            )
                                this._fuseNavigationService.updateNavigationItem(
                                    "admin-branding",
                                    { hidden: true }
                                );
                            if (
                                isAdmin == true &&
                                pricePlans.includes(
                                    PricePlanTypes.DEVELOPMENT
                                ) == false
                            )
                                this._fuseNavigationService.updateNavigationItem(
                                    "admin-courses",
                                    { hidden: true }
                                );
                            if (
                                pricePlans.includes(PricePlanTypes.CULTURE) ==
                                false
                            )
                                this._fuseNavigationService.updateNavigationItem(
                                    "suggestions",
                                    { hidden: true }
                                );
                            if (
                                pricePlans.includes(PricePlanTypes.CULTURE) ==
                                    false ||
                                pricePlans.includes(
                                    PricePlanTypes.DEVELOPMENT
                                ) == false
                            )
                                this._fuseNavigationService.updateNavigationItem(
                                    "feedback",
                                    { hidden: true }
                                );
                            if (
                                pricePlans.includes(PricePlanTypes.BENEFITS) ==
                                false
                            )
                                this._fuseNavigationService.updateNavigationItem(
                                    "benefits",
                                    { hidden: true }
                                );
                            if (
                                pricePlans.includes(
                                    PricePlanTypes.DEVELOPMENT
                                ) == false
                            )
                                this._fuseNavigationService.updateNavigationItem(
                                    "education",
                                    { hidden: true }
                                );
                            if (
                                pricePlans.includes(PricePlanTypes.BENEFITS) ==
                                false
                            )
                                this._fuseNavigationService.updateNavigationItem(
                                    "my-payrolls",
                                    { hidden: true }
                                );
                        },
                        (error) => {
                            console.log("error:", error);
                        }
                    );
                console.log("ILZ app component, isAdmin:", isAdmin);
            });

        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;

                // Boxed
                if (this.fuseConfig.layout.width === "boxed") {
                    this.document.body.classList.add("boxed");
                } else {
                    this.document.body.classList.remove("boxed");
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith("theme-")) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);

                //ILZ have not found a better way to delete the customize item in the navigation panel (it would be better not to create it but did not want to change fuse)
                this._fuseNavigationService.removeNavigationItem(
                    "custom-function"
                );
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
}
