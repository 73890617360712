export class Notification {
    _id: string;
    user: string;
    icon: string;
    img: string;
    title: string;
    description: string;
    link: string;
    useRouter: string;
    read: boolean;
    channels: string[];
    frecuency: string[];

    /**
     * Constructor
     * 
     * @param notification
     */

    constructor(notification) {
        notification = notification || {};
        this._id = notification._id || null;
        this.user = notification.user || null;
        this.icon = notification.icon || '';
        this.img = notification.img || '';
        this.title = notification.title || '';
        this.description = notification.description || '';
        this.link = notification.link || '';
        this.useRouter = notification.useRouter || '';
        this.read = notification.read || false;
        this.channels = notification.channels || ['email'];
        this.frecuency = notification.frecuency || ['immediately'];
    }

}