import {
    Directive,
    Input,
    OnInit,
    TemplateRef,
    ViewContainerRef
  } from '@angular/core';

import {UserService} from './services/user.service';

@Directive({ selector: '[showAdmin]' })
export class ShowAdminDirective implements OnInit {
  constructor(
    private templateRef: TemplateRef<any>,
    private userService: UserService,
    private viewContainer: ViewContainerRef
  ) {}

  condition: boolean;

@Input() set showAdmin(condition: boolean) {
    this.condition = condition;
  }

  ngOnInit() {
    this.userService.currentUser.subscribe(()=> {      
        let isAdmin=this.userService.getCurrentUserIsAdmin();        
        if (isAdmin && this.condition || !isAdmin && !this.condition) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }        
    })

  }

} 