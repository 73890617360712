export class EnpsPoll {
    _id: string;
    isActive: boolean;
    frequency: string;
    nextDate: string;

    /**
     * Constructor
     *
     * @param expense
     */
    constructor(enpspoll?) {
        enpspoll = enpspoll || {};
        this._id = enpspoll._id || null;
        this.isActive = enpspoll.isActive || false;
        this.frequency = enpspoll.frequency || 'monthly';
        this.nextDate = enpspoll.nextDate || '';
    }
}


