export interface SelectTypeData {
    value:string;
    viewValue: string;
}

export enum province {
    'ARA' = "Araba-Álava",
    'ALB' = "Albacete",
    'ALI' = "Alicante-Alacant",
    'ALM' = "Almería",
    'AST' = "Asturias",
    'AVI' = "Ávila",
    'BAD' = "Badajoz",
    'BAR' = "Barcelona",
    'BUR' = "Burgos",
    'CAC' = "Cáceres",
    'CAD' = "Cádiz",
    'CAN' = "Cantabria",
    'CAS' = "Castellón",
    'CIU' = "Ciudad Real",
    'COR' = "Córdoba",
    'ACO' = "A Coruña",
    'CUE' = "Cuenca",
    'GIR' = "Girona",
    'GRA' = "Granada",
    'GUA' = "Guadalajara",
    'GIP' = "Gipuzkoa",
    'HUEL' = "Huelva",
    'HUES' = "Huesca",
    'ILL' = "Illes Balears",
    'JAE' = "Jaén",
    'LEO' = "León",
    'LLE' = "Lleida",
    'MAD' = "Madrid",
    'MAL' = "Málaga",
    'MUR' = "Murcia",
    'NAV' = "Navarra",
    'OUR' = "Ourense",
    'PAL' = "Palencia",
    'LAS' = "Las Palmas",
    'PON' = "Pontevedra",
    'RIO' = "La Rioja",
    'SAL' = "Salamanca",
    'SEG' = "Segovia",
    'SEV' = "Sevilla",
    'SOR' = "Soria",
    'TAR' = "Tarragona",
    'SAN' = "Santa Cruz de Tenerife",
    'TER' = "Teruel",
    'TOL' = "Toledo",
    'VALE' = "Valencia",
    'VALL' = "Valladolid",
    'BIZ' = "Bizkaia",
    'ZAM' = "Zamora",
    'ZAR' = "Zaragoza"
}

export const PROVINCES: SelectTypeData[]= [
    {value: province.ARA, viewValue: province.ARA},
    {value: province.ALB, viewValue: province.ALB},
    {value: province.ALI, viewValue: province.ALI},
    {value: province.ALM, viewValue: province.ALM},
    {value: province.AST, viewValue: province.AST},
    {value: province.AVI, viewValue: province.AVI},
    {value: province.BAD, viewValue: province.BAD},
    {value: province.BAR, viewValue: province.BAR},
    {value: province.BUR, viewValue: province.BUR},
    {value: province.CAC, viewValue: province.CAC},
    {value: province.CAD, viewValue: province.CAD},
    {value: province.CAN, viewValue: province.CAN},
    {value: province.CAS, viewValue: province.CAS},
    {value: province.CIU, viewValue: province.CIU},
    {value: province.COR, viewValue: province.COR},
    {value: province.ACO, viewValue: province.ACO},
    {value: province.CUE, viewValue: province.CUE},
    {value: province.GIR, viewValue: province.GIR},
    {value: province.GRA, viewValue: province.GRA},
    {value: province.GUA, viewValue: province.GUA},
    {value: province.GIP, viewValue: province.GIP},
    {value: province.HUEL, viewValue: province.HUEL},
    {value: province.HUES, viewValue: province.HUES},
    {value: province.ILL, viewValue: province.ILL},
    {value: province.JAE, viewValue: province.JAE},
    {value: province.LEO, viewValue: province.LEO},
    {value: province.LLE, viewValue: province.LLE},
    {value: province.MAD, viewValue: province.MAD},
    {value: province.MAL, viewValue: province.MAL},
    {value: province.MUR, viewValue: province.MUR},
    {value: province.NAV, viewValue: province.NAV},
    {value: province.OUR, viewValue: province.OUR},
    {value: province.PAL, viewValue: province.PAL},
    {value: province.LAS, viewValue: province.LAS},
    {value: province.PON, viewValue: province.PON},
    {value: province.RIO, viewValue: province.RIO},
    {value: province.RIO, viewValue: province.RIO},    
    {value: province.SAL, viewValue: province.SAL},
    {value: province.SEV, viewValue: province.SEV},
    {value: province.SOR, viewValue: province.SOR},
    {value: province.TAR, viewValue: province.TAR},
    {value: province.SAN, viewValue: province.SAN},
    {value: province.TER, viewValue: province.TER},
    {value: province.TOL, viewValue: province.TOL},
    {value: province.VALE, viewValue: province.VALE},
    {value: province.VALL, viewValue: province.VALL},
    {value: province.BIZ, viewValue: province.BIZ},
    {value: province.ZAM, viewValue: province.ZAM},
    {value: province.ZAR, viewValue: province.ZAR}
]

export enum statusTypes {
    Pending= "pending",
    Accepted="accepted",
    Rejected= "rejected",
    Done= "done"
}

export enum monthTypes {
    JAN="JAN",
    FEB="FEB",
    MAR= "MAR",
    APR= "APR",
    MAY= "MAY",
    JUN="JUN",
    JUL= "JUL",
    AUG= "AUG",
    SEP= "SEP",
    OCT="OCT",
    NOV= "NOV",
    DEC= "DEC"
}

export enum pollQuestionTypes {
    'FiveOptions' = "fiveOptions",
    'OneToFive' = "oneToFive",
    'ZeroToTen' = "zeroToTen",
    'YesNo' = "yesNo",
    'CategoryList' = "categorylist",
    'ValueList' = "valueList",
    'ZeroToTenCategoryList'= "oneToFiveCategoryList",
    'ZeroToTenValueList'= "oneToFiveValueList",
    'Text' = "text"
}

export enum frequencyTypes {
    Monthly= 'MONTHLY',
    Quarterly= 'QUARTERLY',
    Triannual= 'TRIANNUAL',
    Biannual= 'BIANNUAL',
    Annual= 'ANNUAL',
    Every2Y= 'EVERY2Y',
    Eventual= 'EVENTUAL',
    Occasional= 'OCCASIONAL',
}