import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { EnpsPoll } from '../models';
import { map } from 'rxjs/operators';

@Injectable()
export class EnpsPollService {
  constructor (
    private apiService: ApiService
  ) {}

  getAll(): Observable<EnpsPoll[]> {
    return this.apiService.get(`/enpspolls`)
      .pipe(map(data => data.enpspoll));
  }

  putPolls(pollId, bodyParams) {
    return this.apiService.put(`/enpspolls/`+pollId, bodyParams)
      .pipe(map(data => data.enpspoll));
  }
  
  sendLastPollResult(bodyParams) {
    return this.apiService.get(`/enpspolls/sendLastResult?sendTo=`+bodyParams)
      .pipe(map(data => data));
  }  

  getPollSchedulers() {
    return this.apiService.get(`/pollSchedulers`)
      .pipe(map(data => data.pollSchedulers));
  }
  
  putPollSchedulers(pollSchedulerId, bodyParams) {
    return this.apiService.put(`/pollSchedulers/`+pollSchedulerId, bodyParams)
      .pipe(map(data => data.pollScheduler));
  }
  
  postPollSchedulers(bodyParams) {
    return this.apiService.post(`/pollSchedulers`, bodyParams)
      .pipe(map(data => data.pollScheduler));
  }
  
  postPollsScore(pollId, bodyParams) {
    return this.apiService.post(`/enpspolls/`+pollId+`/fillOut?score=`+bodyParams)
      .pipe(map(data => data));
  }  

}
