import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProvidersService } from 'app/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'provider-add-dialog',
  templateUrl: './provider-add-dialog.component.html',
  styleUrls: ['./provider-add-dialog.component.scss']
})
export class ProviderAddDialogComponent implements OnInit {

  providerAddForm: UntypedFormGroup

  saveLoading = false  

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _matDialogRef: MatDialogRef<ProviderAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _providersService: ProvidersService,        
    private _translateService: TranslateService,
    private _snackBar: MatSnackBar
  ) { 

  }

  ngOnInit() {
    this.providerAddFormInit()
  }

  // Provider add form initialisation
  providerAddFormInit() {
    this.providerAddForm = this._formBuilder.group({
      name: [null],
      webLink: [null]
    })
  }

  // Insert provider
  insertProvider(providerRowData) {    
    this.saveLoading = true
    let params = {
      provider: {
        name: providerRowData.name,
        webLink: providerRowData.webLink,
      }
    }    
    this._providersService.postProviders(params).subscribe((response) => {
      this.saveLoading = false
      if(response) {
        this.openSnackBar(this._translateService.instant('COMMON_MSG.SUCCESSFULLY_SAVE'))
        this.closeDialog('1')
      }
    }, (error) => {
      this.saveLoading = false
      this.openSnackBar(this._translateService.instant('COMMON_MSG.UNABLE_TO_CONNECT')+' insertProvider')
    }) 
  }  

  // Close dialog
  closeDialog(value) {
    if(value) {
      this._matDialogRef.close(value)
    } else {
      this._matDialogRef.close()
    }
  } 
  
  openSnackBar(message: string) {
    let action = this._translateService.instant('COMMON_MSG.CLOSE')
    this._snackBar.open(message, action, {
      duration: 3000
    })
  }  

}
