import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'benefit-side-bar',
  templateUrl: './benefit-side-bar.component.html',
  styleUrls: ['./benefit-side-bar.component.scss']
})
export class BenefitSideBarComponent implements OnInit {

  // Get the type current user
  @Input() userType: any
  
  // Get benefit info data
  @Input() benefitInfoData: any

  // Get benefit saving data
  @Input() benefitsSavings: any

  // Call update benefit status by eventemitter
  @Output() updateBenefitStatus = new EventEmitter<string>()
  
  // Call contract dialog open by eventemitter
  @Output() contractDialogOpen = new EventEmitter<string>()

  // Call edit benefit call by eventemitter
  @Output() gotoEditBenefit = new EventEmitter<string>()  

  // Get benefit average rating
  @Input() benefitAverageRating: any
  // Get benefit reviews data
  @Input() benefitReviewsData: any
  
  // Get configMonthsDialog
  @Input() configMonthsDialog: any
  // Get configAmountValue
  @Input() configAmountValue: any

  // Get contractButton value
  @Input() contractButton: any  

  constructor() { }

  ngOnInit() {
  }

  updateBenefit(value) {
    this.updateBenefitStatus.emit(value)
  }
  
  contractDialog() {
    this.contractDialogOpen.emit()
  }
  
  // Open edit benefit component
  editBenefitPage() {
    this.gotoEditBenefit.emit()
  }  

}
