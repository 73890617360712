import {SelectTypeData} from 'app/core';

export class User {
    _id: string;
    avatar: string;
    company: string; 
    username: string;
    email: string;
    token: string;
    active: boolean; 
    roles: string[];
    cif: string; //temp property in case of company admin user register
    companyName: string; //temp property in case of company admin user register
    name: string;
    lastName1: string;
    lastName2: string;
    //Financial data
    grossSalary: number;
    //yearlySalaryNum: number;
    birthDate: string;
    civilStatus: string;    
    taxProvince: string;
    //contractType: string;
    profCategory: string;
    //hasGeoMobility: boolean;
    childBirthDates: string[];
    numOver65Charge: number;
    numOver75Charge: number;
    //Benefits data
    hasSchoolBenefit: boolean;
    //Preferences
    lang: string;
    theme: string;
    notifications: string;
    notificationChannel: string;
    notificationFrequency: string;
    supervisor: Object;
}

export enum role {
    'ADMIN' = "ADMIN",
    'GESTOR_ADMIN' = "GESTOR,ADMIN",
    'USER' = "USER"
}

export const ROLES: SelectTypeData[]= [
    { value: role.ADMIN, viewValue: role.ADMIN},
    { value: role.GESTOR_ADMIN, viewValue: role.GESTOR_ADMIN},
    { value: role.USER, viewValue: role.USER}
]

export enum civilStatus {
    'SINGLE' = "SINGLE",
    'MARRIED' = "MARRIED",
    'DIVORCED' = "DIVORCED",
    'WIDOWER' = "WIDOWER"
}

export const CIVILSTATUSES: SelectTypeData[]= [
    { value: civilStatus.SINGLE, viewValue: "SOLTERO"},
    { value: civilStatus.MARRIED, viewValue: "CASADO"},
    { value: civilStatus.DIVORCED, viewValue: "DIVORCED OR SEPARATED"},
    { value: civilStatus.WIDOWER, viewValue: "VIUDO"}
]


export enum profCategory {
    'ING_LICENCIADO' = "ING_LICENCIADO",
    'TECNICO_PERITO_AYUD_TIT' = "TECNICO_PERITO_AYUD_TIT",
    'JEFE_ADMIN_TALLER' = "JEFE_ADMIN_TALLER",
    'AYUDANTE_NO_TIT' = "AYUDANTE_NO_TIT",
    'OFICIAL_ADMIN' = "OFICIAL_ADMIN",
    'SUBALTERNO' = "SUBALTERNO",
    'AUXILIAR_ADMIN' = "AUXILIAR_ADMIN",
    'OFICIAL_1_2' = "OFICIAL_1_2",
    'OFICIAL_3_ESP' = "OFICIAL_3_ESP",
    'PEON' = "PEON",
    'UNDER18' = "UNDER18"
}

export const PROFCATEGORIES: SelectTypeData[]= [
    { value: profCategory.ING_LICENCIADO, viewValue: "Ingenieros y Licenciados"},
    { value: profCategory.TECNICO_PERITO_AYUD_TIT, viewValue: "Ingenieros Técnicos, Peritos y Ayudantes Titulados"},
    { value: profCategory.JEFE_ADMIN_TALLER, viewValue: "Jefes Administrativos y de Taller"},
    { value: profCategory.AYUDANTE_NO_TIT, viewValue: "Ayudantes no Titulados"},
    { value: profCategory.OFICIAL_ADMIN, viewValue: "Oficiales Administrativos"},
    { value: profCategory.SUBALTERNO, viewValue: "Subalternos"},
    { value: profCategory.AUXILIAR_ADMIN, viewValue: "Auxiliares Administrativos"},
    { value: profCategory.OFICIAL_1_2, viewValue: "Oficiales de primera y de segunda"},
    { value: profCategory.OFICIAL_3_ESP, viewValue: "Oficiales de tercera y especialistas"},
    { value: profCategory.PEON, viewValue: "Peones"},
    { value: profCategory.UNDER18, viewValue: "Trabajadores menores de 18 años, cualquiera"}
]

export enum lang {
    //'EN' = "EN",
    'ES' = "ES",
    'EUS' = "EUS"
}

export const LANGUAGES: SelectTypeData[]= [
    //{ value: "en", viewValue: lang.EN},
    { value: "es", viewValue: lang.ES},
    { value: "eus", viewValue: lang.EUS}
]

export enum notificationChannel {
    'email' = "email",
    //'whatsapp' = "whatsapp",
    //'both' = "both",   
}

export const CHANNEL: SelectTypeData[]= [
    {value: notificationChannel.email, viewValue: "Por email"},
    //{value: notificationChannel.whatsapp, viewValue: "Por whatsapp"},
    //{value: notificationChannel.both, viewValue: "Por ambos"},
]

export enum notificationFrequency {
    'immediately' = "immediately",
    'weekly'  = "weekly",
    'monthly' = "monthly",
    'never' = "never"
}

export const FREQUENCY: SelectTypeData[]= [
    {value: notificationFrequency.immediately, viewValue: "Permitir que se reciban notificationes inmediatamente"},
    {value: notificationFrequency.weekly, viewValue: "Permitir que se reciban notifictiones cada semana"},
    {value: notificationFrequency.monthly, viewValue: "Permitir que se reciban notificaciones cada mes"},
    {value: notificationFrequency.never, viewValue: "Permitir que nunca se reciban notificaciones"},
]






